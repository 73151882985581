
import { useVista, Vista, ViewModel, core } from "@essenza/react";
import { BookVisita, BookVisitaVM } from "../widget/book/BookVisita";

export function PatientVista() {
    useVista(PatientVVM);
    /*const [patient, data] = useModel(PatientModel);

    useEffect(() => {
       
    }, [patient])*/

    return (
        <Vista>
            <BookVisita />
        </Vista>
    )
}

export function PatientVVM(){
    ViewModel.call(this);
    //this.use(BookVisitaVM).listen("SELECT")
    this.use(ViewModel).listen("SELECT");
}

core.prototypeOf(ViewModel, PatientVVM, {
    intent: { 
        SELECT({data}){
            console.log(data);
            this.context.navigate("folders", data.id);
        }
    }
});