import { useVista, Vista } from "@essenza/react";

export function AttachmentVista() {
    const vm = useVista();

    return (
        <Vista>
            <h1 className="text-center text-lg mt-3">Nessun documento presente</h1>
        </Vista>
    )
}