import './print.css';
import header from "../assets/img/ft_intestazione.gif";
import fheader from "../assets/img/ft_intestazione_fisio.gif";
import paziente from "../assets/img/dati_paziente.gif";
import esamigif from "../assets/img/esami_effettuati.gif";
import { Col, Row, Space } from "antd";
import React from "react";
import { Format } from "../core/dataFormatter";
import {$Type} from "@essenza/react";

export const PrintInvoice = React.forwardRef(({invoice}, ref) => {
    const euro = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
      });
    const titleText = {format: (title)=>{
        if(title === 'VISITA CERTIFICATIVA'){
            if(invoice.certificate === 1)
                return title + ' AGONISTICA';
            else if(invoice.certificate === 2)
                return title + ' NON AGONISTICA';
        }

        return title;
    }}

    if($Type.isString(invoice.date)){
        invoice.date = new Date(invoice.date);
    }

    const payments = ['Pagamento Elettronico', 'Contanti', 'Bonifico']
    const esami = invoice.items
        ? invoice.items.map((exam, index) =>  exam.title ?
            <Row className="p-value">
                <Col span={4}>{exam.id>0?exam.id:index}</Col><Col span={16}>{titleText.format(exam.title.toUpperCase())}</Col><Col span={3} style={{textAlign: 'right'}}>{euro.format(exam.price)}</Col>
            </Row> : null
        ) : null;
    return (
        <div ref={ref} className="invoice">
            <img src={invoice.igroup === 1 ? fheader : header } alt="intestazione Fattura" />
            <Space><h2>{invoice.itype === 'N' ? "NOTA DI CREDITO" : "RICEVUTA SANITARIA" }</h2> <span className="p-title">Numero</span><span className="p-value">{invoice.invid + '/' + invoice.date.getFullYear()}</span> <span className="p-title">del</span><span className="p-value">{Format.moment(invoice.date, 'DD/MM/YYYY')}</span>
            <span className="p-title">Tipo Pagamento</span><span className="p-value">{payments[invoice.payment-1]}</span>
            </Space>
            <img src={paziente} alt="Dati Paziente" width="700" />
            <Row>
                <Col span={4} className="p-title">Codice Paziente : </Col><Col span={8} className="p-value">{invoice.idpatient}</Col>
            </Row>
            <Row>
                <Col span={4} className="p-title">Codice Cartella : </Col><Col span={8} className="p-value">{invoice.idfolder}</Col><Col span={12}>Egr. Sig.</Col>
            </Row>
            <Row>
                <Col span={4} className="p-title">Codice Fiscale : </Col><Col span={8} className="p-value">{invoice.fiscalcode}</Col><Col span={12} className="p-value">{invoice.fullname}</Col>
            </Row>
            <Row>
                <Col span={12}></Col><Col span={12} className="p-value">{invoice.street}</Col>
            </Row>
            <Row>
                <Col span={12}></Col><Col span={12} className="p-value">{invoice.locality + ' (' + invoice.city + ')'}</Col>
            </Row>
            <img src={esamigif} alt="Prestazioni effettuate" width="700" />
            <Row className="p-title">
                <Col span={4} >Esame</Col><Col span={16}>Descrizione</Col><Col span={3} style={{textAlign: 'right'}}>Da Pagare</Col>
            </Row>
            {esami}
            <Row style={{marginTop: '8px'}}>
                <Col span={20} className="p-title">Totale esami a carico del paziente</Col><Col span={3}  className="p-value" style={{textAlign: 'right'}}>{euro.format(invoice.taxable)}</Col>
            </Row>
            {invoice.tax? 
            <Row>
                <Col span={20} className="p-title">Importo bollo sull'originale</Col><Col span={3} className="p-value" style={{textAlign: 'right'}}>{euro.format(invoice.tax)}</Col>
            </Row>:null}
            <Row>
            <Col span={20}  className="p-title">Totale fattura</Col><Col span={3} className="p-value" style={{textAlign: 'right'}}>{euro.format(invoice.total)}</Col>
            </Row>
            <Row className="p-title" style={{marginTop: '8px'}}>
            <Col span={11} ></Col><Col span={12} style={{textAlign: 'right'}}>Totale esami esente I.V.A. Art. 10/18 D.P.R. 633/12 e succ. mod.</Col>
            </Row>
        </div>
    )
});



