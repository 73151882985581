
import { Patient, PatientVM } from "../patient/form";
import { PatientLite } from "../patient/lite";
import { VisitList } from "../visit/list";
import { useWidget, Widget, core, ViewModel } from "@essenza/react";

export function Fisio({ folder, validate, lite }) {
    const vm = useWidget(FisioVM);
    return (
        <Widget>
            {lite ? <PatientLite source={folder.patient} /> : <Patient schema={vm.createScheme(folder, validate)} source={folder.patient} />}
            <div className="mt-2 p-4 bg-white rounded-md shadow">
                <VisitList source={folder.visits} exams={[{ title: 'VISITA MEDICA SPECIALISTICA' }, 
                    { title: 'AGOPUNTURA + TECARTERAPIA' }, { title: 'AGOPUNTURA + TECARTERAPIA + MASSOTERAPIA' }, 
                    { title: 'AGOPUNTURA + TECARTERAPIA + MOBILIZZAZIONE ARTICOLARE' }, { title: 'MESOTERAPIA ' },
                    { title: "ONDE D'URTO" },{ title: 'AGOPUNTURA' },{ title: 'TECAR' },]} />
            </div>
        </Widget>
    )
}

export function FisioVM() {
    ViewModel.call(this);
    this.use(PatientVM).as("patient");
}

core.prototypeOf(ViewModel, FisioVM, {
    createScheme: (folder, validate) => {
        return { complete: validate || (folder?.visits.length > 0 && folder?.visits[folder.visits.length - 1].state !== undefined) }
    },

    intent: {
        async VALIDATE({token}){
            let result = await this.patient.form.validate(true);
            token.stopFlow = !result.isValid;
            return result;
        }
    }
});