import { useWidget, Widget, core, ViewModel, FormVM } from "@essenza/react";
import { AgendaEvent } from "../agenda/event";
import { PatientLite } from "../patient/lite";
import { Divider } from "antd";
import { FolderSelection, FolderSelectionVM } from "../folder/selection";
import { PatientVM } from "../patient/form";

/*function BookSelectionControl(c) {
    c.skin = BookSelection;
    c.intent = {};

    c.observe(FolderSelection, "SELECT").override(async ({ value: folder, model, flow }) => {
        const visit = await model.find(AgendaItem, "visit-lite").validate();
        const patient = await model.find(PatientLite, "patient-lite").validate();
        
        if (visit.isValid && patient.isValid) {

            folder.patient = patient.data;

            if (!folder.id && folder.itype === 0) {
                visit.data.exams = [{ idoctor: visit.data.idoctor, title: 'Visita certificativa' }];
            }

            folder.visits.unshift(visit.data);
            console.log("BS-DEBUG",folder);
            c.source(FolderModel).item = folder; //Format??? Ma qui è add???
        }
        else
            flow.Break();
    });
}*/

export function BookSelection({ visit, patient }) {
    const vm = useWidget(BookSelectionVM);
    return (
        <Widget>
            <div className="">
                <AgendaEvent es-id="#visit" source={visit} style={{ marginTop: '8px' }} />
            </div>
            <Divider />
            <PatientLite source={patient} />
            <FolderSelection patient={patient} />
        </Widget>
    );
}

export function BookSelectionVM() {
    ViewModel.call(this);
    this.bind(FolderSelectionVM).as("folder").listen("SELECT");
    this.use(FormVM, "#visit").as("visit");
    this.use(PatientVM).as("patient");
}

core.prototypeOf(ViewModel, BookSelectionVM, {
    intent: {
        async SELECT({ data: folder, token }) {
            console.log("SELECT FOLDER IN WIDGET", folder);

            const visit = await this.visit.form.validate(true);
            const patient = await this.patient.form.validate(true);

            if (visit.isValid && patient.isValid) {

                folder.$patient = patient.data;

                if (folder.id<1 && folder.itype === 0) {
                    visit.data.$exams = [{ idoctor: visit.data.iddoctor, title: 'Visita certificativa' }];
                }

                folder.$visits.unshift(visit.data);
                console.log("BS-DEBUG", folder);
            }
            else
                token.breakFlow = true;
        }
    }
});


