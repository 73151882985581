import {DataObject, core} from "@essenza/react";

export function BaseInvoice(etype) {
    DataObject.call(this, etype); 
    
    /* In teoria li definisce da schema*/
    this.taxable = 0;
    this.tax = 0; //bollo
    this.vat = 0;
    this.total = 0;
    this.items = [];

    //this._items = undefined;
    //this.formatTax = undefined;
    this.formatVat = (v) => this.vat = v * 22 / 100;
    this.ischema = { id: "id", title: "title", price: "price" }
    this.formatters = undefined; //[] array di formatters che settano variabile e rendono il valore da sommare al totale

    this.removeItem = function (item) {
        //Cercare tramite id, se lo trovo lo tolgo da array e faccio update;
        //this.update();
    }

    this.importItems = function (items, schema) {
        schema = schema || this.ischema;
        if (!items || !Array.isArray(items)) return;
        const _items = [];
        for (let index = 0; index < items.length; index++) {
            const item = items[index];
            _items.push({ code: "A" + item[schema.code], title: item[schema.title], price: item[schema.price] })
        }

        this.$items = _items;

        if(this.$items){
            this.$items.forEach(item => item.observable()); 
        }

        this.update();
    }

    this.setPatient = function(patient){
        
    }

    //this.onTargetChanged();

    //this.procedure("invid", " COALESCE((SELECT Max(COALESCE(invid,0))+1 FROM invoice WHERE date_part('year',current_date)=year),1) ");
}

core.prototypeOf(DataObject, BaseInvoice,{
    $$update: function () {
        if (this.$items && this.$items.length > 0) {
            this.$taxable = 0;
            this.total = 0;
            console.log("UPDATE INVOICE", this.taxable );
            for (let k = 0; k < this.items.length; k++) {
                this.$taxable += Number(this.items[k].price) || 0;
                console.log("UPDATE ITEM", this.$taxable, Number(this.items[k].price) );
            }

            this.total += this.taxable;

            console.log("UPDATE INVOICE TOTAL", this.total, this.taxable, this.$taxable, this.formatTax);

            if (this.formatTax) {
                this.formatTax(this.taxable);
                this.total += this.tax;
            }

            if (this.formatVat) {
                this.formatVat(this.taxable);
                this.total += this.vat;
            }

            if (this.formatters) {
                for (let k = 0; k < this.formatters.length; k++) {
                    this.total += this.formatters[k](this.taxable);
                }
            }
        }
    },

    addItem: function (item) {
        if (!this.items)
            this.items = [];

        this.$items.push(item);

        item.observable();
        item.listen("MUTATING", token => {
            token.data.field === "price" && this.update();
        });

        this.update();
    },

    import: function (item, schema) {
        schema = schema || this.ischema;
        if (!item) return
        this.addItem({ code: "A" + item[schema.id], title: item[schema.title], price: item[schema.price] });
    } 
});

