import { Input } from "antd";
import { useMemo } from "react";
import { useState } from "react"

export function EditableText({ field, data, onChanging, numeric }) {
    const [editable, setEditable] = useState(false);

    const target = useMemo(() => data || {}, [data]);

    if (!field || !target) return null;

    const onblur = (e) => {
        let v = e.target.value;

        if (v !== target[field]) {
            if(numeric){
                let value = Number(v);
                v = isNaN(value) ? Number(v.replace(/[^0-9\.|\,]/g,'').replace(",",".")) : value; //.replace("\\.\\.+" , ".")
            }

            if (onChanging)
                onChanging({ value: v, target, field });
console.log("onchanging", target, field, v);
            target[(target.$$typeof === Symbol.for('es.dataobject')?'$':'') + field] = v;
            //target.mutation.notify();
        }

        setEditable(false);
    }

    return (editable
        ? <Input autoFocus onBlur={onblur} defaultValue={target[field]}></Input>
        : <div className="w100 h100" style={{ minHeight: '16px' }} onMouseOver={() => setEditable(true)}>{target[field]}</div>
    )
}