import React from "react";
import { useWidget, Widget, core, ViewModel, useForm, Form, FormItem, FormVM, } from "@essenza/react";
import { Input, Select, } from "antd";

export function Sportcard({ source }) {
	const vm = useWidget(SportcardVM);
	const form = useForm(source, { rules: vm.rules });

	return (<Widget>
		<Form form={form} layout="vertical" className="flex gap-3">
			<FormItem className="w-20" label="Tipo" name="kind">
				<Select >
					<Select.Option value="MS01">MS01</Select.Option>
					<Select.Option value="MS02">MS02</Select.Option>
					<Select.Option value="MS03">MS03</Select.Option>
					<Select.Option value="MS04">MS04</Select.Option>
					<Select.Option value="MS05">MS05</Select.Option>
					<Select.Option value="MS06">MS06</Select.Option>
					<Select.Option value="MS07">MS07</Select.Option>
					<Select.Option value="MS08">MS08</Select.Option>
					<Select.Option value="MS09">MS09</Select.Option>
					<Select.Option value="MS10">MS10</Select.Option>
				</Select>
			</FormItem>
			<FormItem label="Disciplina" name="title">
				<Input>
				</Input>
			</FormItem>
			<button className="btn-dark bg-sec" onClick={() => vm.emit("SUBMIT", source)}>Inserisci</button>
		</Form>
	</Widget>
	)
}

export function SportcardVM() {
	ViewModel.call(this);
}

core.prototypeOf(ViewModel, SportcardVM, {
	intent: {
		SUBMIT({ data }) {
			console.log(data);
		},
	}
});
