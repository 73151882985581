import { BaseInvoice } from "./core/BaseInvoice";
import { Invoice } from "./core/invoice";

export function ConfigureApp(app) {
    //app.setBaseUrl("http://api.centromedicogaleotti.it/");
    app.setBaseUrl("https://cloud.centromedicogaleotti.it/");
    //app.setBaseUrl("https://localhost:7294/");

    //app.configureService({ITask: app})

    app.role.configure(["ADMIN", "OPERATORE"]);

    //UserModel.config.url = { uri: "http://localhost:3000/mobile" }; 

    app.configureType({
        users: {
            fields: {
                id: int, name: string, surname: string, email: string, password: string, tpassword: string, irole: int, username: string, nusername: string, nemail: string, emailvalid: bool,
                phone: string, phonevalid: bool, twofactor: bool, idplatform: int, businessname: string, locked: bool, dlog: date, dlast: date, cf: string,
                vatnumber: string, street: string, num: string, locality: string, code: string, city: string, url: string, complete: bool
            },
        },

        patient: {
            fields: {
                id: int, tname: string, tsurname: string, tfiscalcode: string, temail: string, tphonea: string, dborn: date, tnote: string, tgender: char, blocality: string,
                bcountry: string, booklet: string, dbooklet: date, street: string, locality: string, city: string, code: string, country: string, extra: string,
            },
        },

        invitem: {
            fields: { id: int, idinvoice: int, title: string, code: string, price: decimal, },
        },

        exam: {
            fields: { id: int, title: string, idvisit: int, idpatient: int, iddoctor: int, itype: int, price: money },
        },

        invoice: {
            fields: {//invid: int,
                id: int,  year: int, date: date, fullname: string, taxcode: string, nvat: string, street: string, code: string, locality: string, city: string, idfolder:int, fiscalcode: string,
                country: string, taxable: money, tax: money, vat: money, total: money, state: char, itype: char, reason: string, notinvid: int, notyear: int, idpatient: int, payment: small,
                igroup: small,
            },
            type: Invoice,
            children: [{ name: "items", etype: "invitem", collection: true, link: Link.DOWN_WISE }],
        },

        visit: {
            fields: {
                id: int, dstart: date, dend: date, ttitle: string, idfolder: int, itype: int, tnote: string, state: small, invid: int, iddoctor: int,
                blocked: bool, color: string, idinvoice: int,
            },
            children: [
                { name: "exams", etype: "exam", collection: true, link: Link.DOWN_WISE },
                { name: "invoice", etype: "invoice", collection: false, link: Link.UP_WISE }]
        },

        folder: {
            fields: {
                id: int, iddoctor: int, idpatient: int, itype: int, istate: int, icertificate: int, ipatient: int, isport: int, invoice: bool,
                certid: int, certdate: date, certwidth: int, certex: date, ndoc: string, ddoc: date, fromdoc: string, jsport: 9
            },
            children: [
                { name: "patient", etype: "patient", collection: false, link: Link.UP_WISE },
                { name: "visits", etype: "visit", collection: true, link: Link.DOWN_WISE }]
        },

        comune: {
            fields: { city: string, code: string, country: string, label: string, value: string },
        },

        sport: {
            fields: { id: small, kind: string, title: string },
        },

    });
}

export const bool = 0;
export const small = 1;
const int2 = 1;
export const string = 2;
const varchar = 2;
export const decimal = 3;
export const double = 4;
export const float = 5;
export const int = 6;
export const long = 7;
export const date = 8;
export const money = 3;
export const char = 2;
export const Link = {DOWN_WISE: 'd', UP_WISE: 'u', BIDIRECTIONAL: 'b',};