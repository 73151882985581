import { Button, Col, DatePicker, Radio, TimePicker } from "antd";
import { useWidget, widget, Widget, useForm, Form, FormItem, core, FormVM } from "@essenza/react";
import * as yup from 'yup';

export const AgendaBlock = widget(function ({ source, rules, vm }) {
    //const vm = useWidget(FormVM);

    rules = rules || yup.object({
        dstart: yup.date().required("Informazione richiesta").nullable(),
        dend: yup.date().required("Informazione richiesta").nullable(),
    });

    const form = useForm(source, { rules: rules });
    return (
        <Widget>
            <Form form={form} layout='vertical' className="flex flex-col gap-3">
                <FormItem name="idfolder">
                    <Radio.Group>
                        <Radio value={123}>Medicina Sportiva</Radio>
                        <Radio value={122}>Fisioterapia</Radio>
                    </Radio.Group>
                </FormItem>
                <FormItem name="dstart">
                    <DatePicker style={{ width: '120px' }} format="DD-MM-YYYY" placeholder="Data Inizio"></DatePicker>
                </FormItem>
                <FormItem name="dstart" >
                    <TimePicker allowClear={false} style={{ width: '120px' }} minuteStep={15} format="HH:mm" placeholder="Orario Inizio"></TimePicker>
                </FormItem>
                <FormItem name="dend">
                    <DatePicker style={{ width: '120px' }} format="DD-MM-YYYY" placeholder="Data Fine"></DatePicker>
                </FormItem>
                <FormItem name="dend">
                    <TimePicker allowClear={false} style={{ width: '120px' }} minuteStep={15} format="HH:mm" placeholder="Orario Fine"></TimePicker>
                </FormItem>
                <Button className="btn-dark bg-pri w-full" onClick={() => vm.emit("SAVE")}>Conferma</Button>
            </Form>
        </Widget>
    )
}, FormVM);

/*export function AgendaBlockVM() {
    ViewModel.call(this);
}

core.prototypeOf(ViewModel, AgendaBlockVM, {
    get rules() {
        return yup.object({
            dstart: yup.required("Informazione richiesta"),
            dend: yup.required("Informazione richiesta"),
        })
    },
    intent: { //swipe or override
        SAVE: async function ({ context }) {
            const validation = await this.form.validate(true);
            if (validation.isValid) {
                console.log("AGENDA BLOCK IS VALID", validation.data);
                validation.data.save();
            }
        },
    }
});*/