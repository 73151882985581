import { Select, DatePicker, TimePicker } from "antd";
import { useWidget, Widget, useForm, useValue, Form, FormItem, FormVM, widget } from "@essenza/react";
import * as yup from 'yup';

export const AgendaEvent = widget(function ({ source, vm }) {
  const form = useForm(source, { rules: vm.rules });
  const doctors = useValue("doctors");
  return (
    <Widget>
      <Form form={form} autoComplete="off" layout="vertical" className="py-4 px-2 flex gap-2 flex-wrap bg-white rounded-md shadow">
        <FormItem className="mb-0" name="dstart" label="Data">
          <DatePicker style={{ width: '120px' }} format="DD-MM-YYYY" placeholder="Data"></DatePicker>
        </FormItem>
        <div className="flex gap-2">
          <FormItem name="dstart" className="mb-0" label="Inizio">
            <TimePicker allowClear={false} style={{ width: '120px' }} minuteStep={15} format="HH:mm" placeholder="Orario Inizio"></TimePicker>
          </FormItem>
          <FormItem name="dend" className="mb-0" label="Fine">
            <TimePicker allowClear={false} style={{ width: '120px' }} minuteStep={15} format="HH:mm" placeholder="Orario Fine"></TimePicker>
          </FormItem>
        </div>
        <FormItem name="iddoctor" label="Esecutore" className="flex-auto sm:flex-none mb-0">
          <Select defaultValue={1} fieldNames={{ value: "id" }} placeholder="Esecutore" options={doctors} >
          </Select>
        </FormItem>
      </Form>
    </Widget>
  )
}, FormVM);

//labelCol={{ span: 8, offset: 0 }} wrapperCol={{ span: 16, offset: 0 }} layout="inline"