import { useVista, useModel, Vista, ViewModel, core, Printer } from "@essenza/react";
import { InvoiceModel } from "../data/invoice";
import { Row, Col, Button, message, Checkbox, Radio, notification } from 'antd';
import { InvoiceTable } from "../widget/invoice/table";
import { PrintInvoice } from "../print/PrintInvoice";

export function InvoiceVista() {
    const vm = useVista(InvoiceVVM);
    const [model, data] = useModel(InvoiceModel);

    vm.$$init(vm => {
        const ndata = vm.context.navdata;
        if (isNaN(ndata)) {
            vm.invoice = ndata.invoice;
            vm.values = ndata.values;
        }
        else {
            model.detail(ndata);
        }
    });

    let invoice = data || vm.invoice;

    if (!invoice) return <h1 className="text-center text-lg">Nessun documento da visualizzare</h1>;

    return (
        <Vista>
            <div className="w100 py-md">
                <div className="centered block-max-width ">
                    <Row align='middle' className="my-2" >
                        <Col flex="auto"><h1 className='font-bold'>{invoice.itype === 'F' ? "Fattura" : "Nota di credito"}</h1></Col>


                        {
                            invoice.id > 0
                                ? <>
                                    <Col flex="none"><Button className='btn-pri' onClick={() => vm.emit("PRINT", invoice)}>STAMPA</Button></Col>
                                    {invoice.itype === 'F' && <Col flex="none"><Button className='btn-pri' onClick={() => vm.emit("REMOVE", invoice)}>ELIMINA FATTURA</Button></Col>}
                                </>
                                : <>
                                    <Col>
                                        <Radio.Group onChange={(e) => invoice.payment = e.target.value} defaultValue={1}>
                                            <Radio value={1}>Pos</Radio>
                                            <Radio value={2}>Contanti</Radio>
                                            <Radio value={3}>Bonifico</Radio>
                                        </Radio.Group>
                                    </Col>
                                    <Col flex="none"><Checkbox defaultChecked={true}>STAMPA</Checkbox></Col><Col flex="none">
                                        <Button className='btn-pri mr-sm' onClick={() => vm.emit("EMIT", invoice)}>EMETTI FATTURA</Button>
                                    </Col>
                                </>
                        }
                        <Col flex="none"><Button className='' onClick={() => vm.context.navigate("folder")}>INDIETRO</Button></Col>
                    </Row>
                    <InvoiceTable source={invoice.$items} />
                </div>
            </div>
        </Vista>
    )
}

export function InvoiceVVM() {
    ViewModel.call(this);
}

core.prototypeOf(ViewModel, InvoiceVVM, {
    intent: {
        PRINT({ data }) {
            data.$$update();
            console.log("INV-INIT", data);
            data.save().then(()=>{
                const printer = {};
                this.context.openModal({
                    kind: "confirm",
                    content: <div><Printer document={PrintInvoice} data={{ invoice: data }} printer={printer} /></div>,
                    title: "STAMPA FATTURA",
                    okText: "STAMPA",
                    cancelText: "ANNULLA",
                    centered: true,
                    width: 850,
                    onOk: () => printer.print(),
                })
            })
        },

        EMIT({ data }) {
            data.$$update();
            data.mutate("$invid", " COALESCE((SELECT Max(COALESCE(invid,0))+1 FROM invoice WHERE date_part('year',current_date)=year AND igroup=" + data.igroup + "),1) ");
            data.node.returning = "invid";

            data.save({ queryOp: "api/inv", data: this.values }).then(r => {
                const printer = {};
                data.invid = JSON.parse(r.data.mutation.invoice[0].data).invid;
                this.context.openModal({
                    kind: "confirm",
                    content: <div><Printer document={PrintInvoice} data={{ invoice: data }} printer={printer} /></div>,
                    title: "STAMPA FATTURA",
                    okText: "STAMPA",
                    cancelText: "ANNULLA",
                    centered: true,
                    width: 850,
                    onOk: () => printer.print(),
                })
            })
        },

        REMOVE({data}){
            this.$invoice.remove(data.id, data.igroup).then(r=>notification.info({message: r ? "Nota di credito emessa con successo" : "Fattura eliminata con successo"}));
        }
    }
});
