import { Button, Table } from "antd";
import { Widget, useWidget, ViewModel, core, $Data } from "@essenza/react";
import { EditableText } from "../../component/EditableText";
import { CloseCircleOutlined } from '@ant-design/icons';
import { useMemo } from "react";

export function InvoiceTable({ source }) {
    const vm = useWidget(InvoiceTableVM);
    //if (!vm.list)
        vm.list = source;
    const col = useMemo(() => Cols(vm), [vm])
    const newItem = {code: '', title: '', price: 0}
    return (<Widget>

        {/* <Table dataSource={vm.list} columns={col} pagination={null} /> */}
        <div className="flex p-2 bg-gray-400 rounded-2xl">
            <div className="basis-1/5">Codice</div>
            <div className="flex-auto mx-2">Descrizione</div>
            <div className="basis-1/5">Importo</div>
            <CloseCircleOutlined className="cursor-pointer invisible mx-2" />
        </div>
        {
            source && source.map(item =>
                <div className="flex p-2 mt-2 items-center bg-white rounded-2xl">
                    <div className="basis-1/5">{item.code}</div>
                    <div className="flex-auto mx-2"><EditableText data={item} field="title" /></div>
                    <div className="basis-1/5"><EditableText numeric data={item} field="price" /></div>
                    <CloseCircleOutlined className="cursor-pointer mx-2" onClick={() => { vm.emit("DELETE", item); }} />
                </div>
            )
        }
        <div className="flex p-2 mt-2 items-center bg-white rounded-2xl">
            <div className="basis-1/5">{newItem.code}</div>
            <div className="flex-auto mx-2"><EditableText onChanging={data=>vm.emit("ADD", data)} data={newItem} field="title" /></div>
            <div className="basis-1/5"><EditableText numeric data={newItem} field="price" /></div>
            <CloseCircleOutlined className="cursor-pointer mx-2"  />
        </div>
    </Widget>)
}

export function InvoiceTableVM() {
    ViewModel.call(this);
}

core.prototypeOf(ViewModel, InvoiceTableVM, {
    intent: {
        DELETE({ data }) {
            /*for (var i = 0; i < data.length; i++) {
                if (data[i].id === item.id) {
                  data.splice(i, 1);
                  break;
                }
              }*/
            console.log("DELETE", data);
            if (this.list) {
                this.list.remove(data);
                this.list = [...this.list];//$Data.clone(this.list);
                this.update();
            }
        },

        ADD({ data }) {
            console.log("ADD", data, this.list);
            data.target[data.field] = data.value;
            if (this.list) {
                this.list.push(data.target);
                this.update();
            }
        }
    }
});

function Cols(vm) {
    return [
        {
            title: "Codice",
            dataIndex: "code",
            key: "id",
            width: 70,
        },
        {
            title: "Descrizione",
            render: (text, record) => {
                return <EditableText data={record} field="title" />
            },
            key: "id",
            width: 'auto',
        },
        {
            title: "Importo",
            render: (text, record) => {
                return <EditableText numeric data={record} field="price" />
            },
            key: "id",
            width: 220,
        },
        {
            title: "",
            key: "id",
            render: (text, record) => {
                return (
                    // <Button type="text" onClick={() => { vm.emit("DELETE", record);}}><CloseCircleOutlined  onClick={() => { vm.emit("DELETE", record);}}/></Button>
                    <CloseCircleOutlined className="cursor-pointer" onClick={() => { vm.emit("DELETE", record); }} />
                );
            },
            width: 60,
        },
    ];
}