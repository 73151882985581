import { DataModel, core } from "@essenza/react";

export function FolderModel() {
    DataModel.call(this);
}

core.prototypeOf(DataModel, FolderModel, {
    etype: "folder",

    detail(id) {
        return this.ExecuteQuery("folder_detail", { id: id });
    },

    open(id) {
        if (id > 0)
            return this.ExecuteQuery("folder_open", { id: id });
    },

    of(id) {
        if (id > 0)
            return this.ExecuteQuery("folder_of", { id });
    },

    createState(folder) {
        const state = this.context.mutable({ $booking: true, $agonistico: false, $pending: true, invoicing: false });
        if (folder) {
            state.booking = folder.visits[0].state === undefined; //Solo alla prima prenotazione???
            state.agonistico = folder.icertificate === 1;
            state.pending = folder.istate === 0;
            for (let k = 0; k < folder.visits.length; k++) {
                if (folder.visits[k].state === 0) {
                    state.invoicing = true;
                    break;
                }
            }
        }
        return state;
    }
});