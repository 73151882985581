import { Input, DatePicker } from "antd";
import dayjs from 'dayjs';
import { useWidget, Widget, useForm, Form, FormItem } from "@essenza/react";
import TextArea from "antd/es/input/TextArea";
import { CodiceFiscale, Contact } from "./person";
import * as yup from 'yup';
import { PatientVM } from "./form";

export function PatientLite({ source, schema }) {
    const vm = useWidget(PatientVM); // se non metto niente, come mi ref? se ne ho più di uno? con il path =>  come si forma il path?
    const form = useForm(source, vm.formatSchema(schema));

    return (
        <Widget>
            <Form layout="vertical" form={form} className="py-4 px-2 flex gap-2 flex-wrap bg-white rounded-md shadow" >
                <FormItem name="tsurname" label="Cognome" className="flex-auto sm:min-w-80">
                    <Input placeholder="Cognome" />
                </FormItem>
                <FormItem name="tname" label="Nome" className="flex-auto min-w-72 sm:min-w-80">
                    <Input placeholder="Nome" />
                </FormItem>
                <FormItem name="dborn" label="Data Nascita">
                    <DatePicker style={{ minWidth: '120px' }} format={['DD/MM/YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD']} />
                </FormItem>
                <Contact></Contact>
                <FormItem className="w-full" name="tnote" label="Note">
                    <TextArea rows={2} placeholder="Note" />
                </FormItem>
            </Form>
        </Widget>
    )
}