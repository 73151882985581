import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import locale from 'antd/locale/it_IT';
import { ConfigProvider } from 'antd';
import { registerLicense } from '@syncfusion/ej2-base';

dayjs.locale('it');
registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cWWdCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWX1ccHVdRWReV0F/V0s=');

/* CSS Variables => vedi compatibilità

ConfigProvider.config({
  theme: {
    primaryColor: '#25b864',
  },
});
*/

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ConfigProvider locale={locale} theme={{
    token: {
      colorPrimary: '#BF3A3A',
      colorBgElevated:"#F2F4F6"
    },
    components: {
      Button:{
        defaultGhostBorderColor: '#BF3A3A',
        defaultGhostColor: '#000',
        defaultBg:'#BF3A3A',
      },
      Input:{
        activeBg:"#F2F4F6"
      },
      Menu: {
        itemHoverColor: '#FFFFFF'
      },
    }
  }}>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </ConfigProvider>
);
// "homepage": "/test",
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
