import { DataModel, core } from "@essenza/react";

export function PatientModel() {
    DataModel.call(this);
}

core.prototypeOf(DataModel, PatientModel, {
    etype: "patient",
    search(value){
        return this.ExecuteQuery("patjsearch", {surname: value});
    },

    detail(id){
        return this.ExecuteQuery("patient_detail", {id});
    }
});