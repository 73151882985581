import { Button, Card, Checkbox, Divider } from "antd";
import { ViewModel, core, useWidget, Widget, useModel, Authorize } from "@essenza/react";


//sportIcon from "../../assets/icons/sport.jpg";
import fisioIcon from "../../assets/icon/fisio2.jpg";
import cardioIcon from "../../assets/icon/cardio.jpg";
import certIcon from "../../assets/icon/certificative.jpg";
import { FolderModel } from "../../data/folder";
import { useEffect } from "react";

export function FolderSelection({ patient }) {
    const vm = useWidget(FolderSelectionVM);
    const [model, data] = useModel(FolderModel);
    useEffect(() => {
        patient && vm.context.role.authorize("ADMIN") && model.open(patient.id);
    }, [patient])
    return (<Widget>
        <div className="site-card-wrapper mt-4 bg-white p-4 rounded-md shadow">
            <div className="flex flex-wrap gap-6 justify-center">
                {Array.isArray(data) && data.map((folder) => {
                    let title = "Cartella ";
                    if (folder.itype === 0) {
                        title += "Certificazione " + (folder.icertificate === 1 ? "" : "non") + "Agonistica";
                    }
                    else
                        title += "Fisioterapia"
                    return <Card className="h-72 border-black max-w-xs w-full" hoverable title={title} >

                        <div><b>Data Cartella:</b> {folder.idate}</div>
                        <div><b>Visite Effettuate:</b> {folder.visits.length}</div>
                        {folder.exams && folder.exams[0] && <><h3 className="mt-sm">Accertamenti Richiesti</h3>
                            {folder.exams.map((exam) => <>
                                <div className="flex">
                                    <div className="flex-auto"><Checkbox><b>{exam.title}</b></Checkbox></div>
                                    <div className="flex-none">{exam.itype === 1 ? "ESAME II LIV" : "PRESCRIZIONE"}</div>
                                </div>
                            </>)}
                        </>
                        }
                        <Divider />
                        <p className="text-center">
                            {folder.itype === 0 ? <b>IN SOSPESO</b> : <b>APERTA</b>}
                        </p>
                        <button className="mx-auto mt-2 block btn-dark bg-sec" onClick={() => { vm.emit("SELECT", folder); }}>Prenota</button>
                        {/* <Meta style={{ color: 'red' }} title="PRESCRIZIONE PRESENTE" description="ECG Dinamico Holter Cardiaco" /> */}
                    </Card>
                })
                }
                <Card className="h-72 border-black max-w-xs w-full p-0" hoverable title="Visita Sportiva" >
                    <div className="flex justify-center">
                        <img className="object-contain h-48" height="160" src={certIcon} alt="Visita Sportiva" />
                        <button className="btn-dark bg-sec absolute bottom-2" onClick={() => { vm.emit("SELECT", model.newInstance({ istate: 0, itype: 0, visits: [], iddoctor: 1 })); }}>Prenota</button>
                    </div>
                </Card>

                <Card className="h-72 border-black max-w-xs w-full " hoverable title="Visita Fisioterapica" >
                    <div className="flex justify-center">
                        <img className="object-contain h-48" height="140" src={fisioIcon} alt="Visita Fisioterapica" />
                        <button className="btn-dark bg-sec absolute bottom-2" onClick={() => { vm.emit("SELECT", model.newInstance({ istate: 0, itype: 1, visits: [], iddoctor: 1 })); }}>Prenota</button>
                    </div>
                </Card>
                <Authorize roles="ADMIN">
                    <Card className="h-72 border-black max-w-xs w-full p-0" hoverable title="Visita Cardiologica" >
                        <div className="flex justify-center">
                            <img className="h-48" src={cardioIcon} alt="Visita Cardiologica" />
                            <button className="btn-dark bg-sec absolute bottom-2" onClick={() => { vm.emit("SELECT", model.newInstance({ istate: 0, itype: 2, visits: [], iddoctor: 1 })); }}>Prenota</button>
                        </div>
                    </Card>
                </Authorize>
            </div>
        </div>
    </Widget>)
}

export function FolderSelectionVM() {
    ViewModel.call(this);
}

core.prototypeOf(ViewModel, FolderSelectionVM, {
    intent: {

    }
});