import { Button } from "antd";

export function EventMove({ props, model, vm, data}) {
    let event;
    if(data){
        event = model.newInstance({ id: data.id, source: props })
        event.$dstart = new Date(props.StartTime.getTime());
        const minutes = (data.EndTime.getTime() - data.StartTime.getTime()) / 60000;
        event.$dend = new Date(event.dstart.getTime())
        event.dend.setMinutes(event.dend.getMinutes() + minutes);
    }
    return (
        <div className="text-center" style={{ fontSize: '18px' }}>
            <div >SPOSTA APPUNTAMENTO</div>
            <div className="mt-lg">
                <b>DA {model.formatInterval(data.StartTime, data.EndTime)}</b>
            </div>
            <div style={{ height: '126px' }} className="mt-lg">
                <b> A {model.formatInterval(event.dstart, event.dend)}</b>
            </div>
            <Button className="mt-lg btn-pri" onClick={() => vm.emit("MOVE", event)} style={{ width: "100%" }} block >Conferma</Button>
        </div>)
}