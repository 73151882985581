import React, { useMemo, useState, } from "react";
import { useValue, useWidget, Widget, core, ViewModel, useForm, Form, FormItem, FormVM, PopOverButton } from "@essenza/react";
import { Select, Input, DatePicker, Radio, InputNumber } from "antd";
import { Sportcard, SportcardVM } from "../sport/sportcard";
import * as yup from 'yup';
import dayjs from 'dayjs';
import { SportModel } from "../../data/sport";
import { FolderModel } from "../../data/folder";

export function Certificativecard({ source, schema, state }) {
	const vm = useWidget(CertificativecardVM);

	vm.sports = useValue("sports");
	const doctors = useValue("doctors");

	state = useMemo(() => vm.format(source), [source, vm]);
	schema = useMemo(() => vm.formatSchema(schema), []);

	const form = useForm(source, schema);

	return (<Widget>
		<Form form={form} layout="vertical" className="flex flex-col gap-3 py-4 px-2 bg-white rounded shadow">
			<div className="flex flex-wrap gap-3 ">
				<FormItem className="grow min-w-80" label="Tipo Certificato" name="icertificate">
					<Select onChange={value => state.$agonistico = value === 1}>
						<Select.Option value={1}>Agonistico</Select.Option>
						<Select.Option value={2}>Non Agonistico</Select.Option>
					</Select>
				</FormItem>
				<FormItem className="grow min-w-80" label="Medico" name="iddoctor">
					<Select fieldNames={{ value: "id" }} options={doctors}>
					</Select>
				</FormItem>
				<FormItem className="grow min-w-80" label="Atleta" name="ipatient">
					<Select>
						<Select.Option value={1}>ATLETA MAGGIORENNE</Select.Option>
						<Select.Option value={2}>ATLETA MAGGIORENNE ESENTE PATOLOGIA</Select.Option>
						<Select.Option value={3}>ATLETA MINORENNE ESENTE</Select.Option>
						<Select.Option value={4}>ATLETA MINORENNE PAGANTE</Select.Option>
						<Select.Option value={5}>ATLETA OVER 40</Select.Option>
						<Select.Option value={6}>ATLETA PORTATORE DI HANDICAP</Select.Option>
					</Select>
				</FormItem>
			</div>
			<FormItem label="Disciplina" name="jsport">
				<Select mode="multiple" options={vm.sports} fieldNames={{ label: "title", value: "id", }} onMouseEnter={(e) => e.target.focus()} showSearch optionFilterProp="title" >
				</Select>
			</FormItem>
			<PopOverButton title="Altra Disciplina" hide={vm.hide} content={<Sportcard source={new SportModel().newInstance()} />} style={{ marginLeft: '24px' }} className="pl-2 mt-2 btn-dark bg-pri" />

			{!state.booking && state.agonistico &&
				<div className="flex gap-3">
					<FormItem label="Numero" name="certid">
						<Input>
						</Input>
					</FormItem>
					<FormItem label="Validità" name="certwidth">
						<InputNumber>
						</InputNumber>
					</FormItem>
					<FormItem label="Documento Num." name="ndoc">
						<Input>
						</Input>
					</FormItem>
					<FormItem label="Rilasciato da" name="fromdoc">
						<Input>
						</Input>
					</FormItem>
					<FormItem label="Rilasciato Il" name="ddoc">
						<DatePicker format={['DD/MM/YYYY', 'DD-MM-YYYY']} />
					</FormItem>
					<button onClick={() => vm.emit("CERTIFICATE")} className="btn-dark bg-sec">STAMPA</button>
				</div>
			}

			{
				!state.booking &&
				<FormItem label="Esito" name="istate"  >
					<Radio.Group onChange={(e) => { console.log("ESITO LOG", e.target.value); state.$pending = e.target.value === 0; }} >
						<Radio value={1}>IDONEO</Radio>
						<Radio value={2}>NON IDONEO</Radio>
						<Radio value={0}>IN SOSPESO</Radio>
					</Radio.Group>
				</FormItem>
			}
			{
				!state.agonistico && <button onClick={() => vm.emit("CERTIFICATE")} className="btn-dark bg-sec">STAMPA CERTIFICATO</button>
			}
		</Form>
	</Widget>
	)
}

export function CertificativecardVM() {
	FormVM.call(this);
	this.bind(SportcardVM).as("sport").listen("SUBMIT", this);
}

core.prototypeOf(FormVM, CertificativecardVM, {

	format(folder) {
		const state = new FolderModel().createState(folder);
		state.listen("MUTATING", this);
		return state;
	},

	$$schema: () => ({
		rules: {default:yup.object({
			isport: yup.number().required().moreThan(0, "Selezionare Disciplina."),
			certwidth: yup.string().required("Richiesto *"),
			ndoc: yup.string().required("Richiesto *").nullable(),
			fromdoc: yup.string().required("Richiesto *").nullable(),
			ddoc: yup.date().required("Richiesto *").nullable(),
		})},
		formatter: {
			ddoc: (v) => v ? dayjs(v, 'YYYY-MM-DD') : null
		}
	}),

	$intent: {
		PRESCRIPTION({ data }) {
			console.log(data);
		},

		EXTRA({ data }) {
			console.log(data);
		},

		CERTIFICATE({ data }) {
			console.log(data);
		},

		async SUBMIT({ data }) {
			const result = await this.sport.form.validate(true);
			result.data.save().then(response => {
				console.log("SUBMIT SPORT", response, this);
				this.context.setSource("sports", [...this.sports, response.item]);//push(response.item)
				this.update();
			})
		}
	}
});

/**
 *  yup.object({
			isport: yup.number().required().moreThan(0, "Selezionare Disciplina."),
			certwidth: yup.string().required("Richiesto *"),
			ndoc: yup.string().required("Richiesto *").nullable(),
			fromdoc: yup.string().required("Richiesto *").nullable(),
			ddoc: yup.date().required("Richiesto *").nullable(),
		}));

		yup.object({
			tfiscalcode: yup.string().required("Codice Fisacle richiesto.").nullable(),
			street: yup.string().required("Indirizzo è una informazione richiesta.").nullable(),
			locality: yup.object().shape({
				label: yup.string().required("Comune è una informazione richiesta.").nullable(),
			}),
			//code: yup.string().required("Cap *").nullable(),
		});

		 model.schema = yup.object({
		tname: yup.string().required("Nome è una informazione richiesta."),
		tsurname: yup.string().required("Cognome è una informazione richiesta."),
	});
 */