import { Button, Col, Popover, Row } from "antd";
import { GiPhone } from "react-icons/gi";
import { BsClock, BsFillPersonFill } from "react-icons/bs";
import { FaFolderOpen } from "react-icons/fa";
import { InfoCircleOutlined } from '@ant-design/icons';
import { useState } from "react";

import { AgendaNote } from "./note";

const visitType = ["Visita Sportiva", "Visita Fisioterapica", "Visita Cardiologica"];
const doctors = ["", "Dott. Galeotti Fabrizio"];
const certificate = ["", " Agonistica", " Non Agonistica"];

export function EventInfo({ props, vm }) {
    const [open, setOpen] = useState(false);

    if(props.itype === 1) return <div>{props.detail}</div>

    return (<div className="e-event-content e-template">
        <div className="e-subject-wrap">
            <Row gutter={16}>
                <Col>
                    <BsClock style={{ fontSize: '18px' }} />
                </Col>
                <Col>
                    {props.StartTime.toLocaleTimeString("it-IT", { hour: '2-digit', minute: '2-digit' })}-{props.EndTime.toLocaleTimeString("it-IT", { hour: '2-digit', minute: '2-digit' })}
                    {'  ' + props.EndTime.toLocaleDateString('it-IT', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}
                </Col>
            </Row>
            <Row style={{ paddingTop: '8px' }} gutter={16}>
                <Col>
                    <GiPhone style={{ fontSize: '18px' }} />
                </Col>
                <Col>
                    {props.PhoneNumber}
                </Col>
            </Row>
            <Row style={{ paddingTop: '8px' }} gutter={16}>
                <Col>
                    <FaFolderOpen style={{ fontSize: '18px' }} />
                </Col>
                <Col>
                    {visitType[props.type] + (certificate[props.icertificate] ? certificate[props.icertificate] : '')}
                </Col>
            </Row>
            <Row style={{ paddingTop: '8px' }} gutter={16}>
                <Col>
                    <BsFillPersonFill style={{ fontSize: '18px' }} />
                </Col>
                <Col>
                    {doctors[props.doctor]}
                </Col>
            </Row>
            <Row style={{ paddingTop: '8px' }} gutter={16}>
                <Col>
                    <Popover
                        content={<AgendaNote es-id="note" source={vm.context.newInstance('patient', { id: props.idpatient, extra: props.extra })} />}
                        title=""
                        trigger="click"
                        open={open}
                        onOpenChange={s => { vm.openPopup = setOpen; setOpen(s); }}
                    >
                        <InfoCircleOutlined style={{ fontSize: '18px' }} />
                    </Popover>
                </Col>
                <Col style={{ color: 'red' }}>
                    <b>{props.extra}</b>
                </Col>
            </Row>
            <Row style={{ paddingTop: '8px' }} gutter={16}>
                <Col span={24}>
                    {props.detail}
                </Col>
            </Row>
            <Row style={{ paddingTop: '8px' }} gutter={16}>
                <Col span={12}>
                    <b>Data Nascita:</b> {props.dborn}
                </Col>
                <Col span={12}>
                    <b>Ultima Visita:</b> {props.last?.split('T')[0]}
                </Col>
            </Row>
            
            <button className="btn-dark bg-sec w-full font-thin text-base mt-2" onClick={() => vm.emit("DETAIL", props.idfolder)}  block >Visualizza</button>
            <button className="btn-dark bg-pri w-full font-thin text-base mt-2" onClick={() => vm.emit("CANCEL", props.id)}  block >Annulla</button>

        </div>
    </div>)
}