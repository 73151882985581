import './print.css';
import header from "../assets/img/ft_intestazione.gif";
import { Col, Row } from "antd";
import React from "react";
import { Format } from "../core/dataFormatter";

const dateFormat = 'DD/MM/YYYY';
const esito = ['_______________________', 'IDONEO', 'NON IDONEO']

export const PrintCompetitiveCertificate = React.forwardRef(({certificate}, ref) => {//({ certificate }) {
    
    return (
        <div ref={ref} className="cert">
            <img src={header} alt="Intestazione Certificato Agonistico" />
            <Row>
                <Col flex="auto"><h2>Cert. N. {certificate.certid + '/' + new Date().getFullYear()}</h2></Col>
                <Col flex="none"></Col>
            </Row>
            <h4 style={{textAlign: 'right'}}>Acc. ASL n° 25048 del 18/12/2022</h4>
            <h4 style={{marginTop: '36px'}}>CERTIFICAZIONE DI IDONEITÀ</h4>
            <h4>ALL'ATTIVITÀ SPORTIVA AGONISTICA</h4>
            <h4>(D.M. 18-2-1982 - L.R. 9/7/2003 n° 35)</h4> 
            <p style={{textAlign: 'justify', marginBottom: '48px', marginTop: '48px'}}>Si dichiara che l'atleta <span className="c-value">{certificate.fullname.toUpperCase()}</span> nato/a a <span className="c-value">{certificate.blocality.toUpperCase()}</span> il <span className="c-value">{Format.moment(certificate.dborn, dateFormat)}</span> residente a <span className="c-value">{certificate.locality.toUpperCase()}</span> in <span className="c-value">{certificate.street.toUpperCase()} </span>
                documento n° <span className="c-value">{certificate.ndoc}</span> rilasciato da <span className="c-value">{certificate.fromdoc}</span> il <span className="c-value">{Format.moment(certificate.ddoc, dateFormat)}</span> è stato sottoposto a visita in data <span className="c-value">{Format.moment(certificate.certdate, dateFormat)}</span> con esecuzione degli
                accertamenti previsti dal D.M. 18.02.82 (Tab. A - Tab. B), ed è stato dichiarato <span className="c-value">{esito[certificate.istate]}</span> alla pratica agonistica dello sport, <div className="c-value-xl">{certificate.sport.toUpperCase()}</div> <div style={{textAlign: 'center'}}>Il predetto certificato
                ha validità di <span className="c-value">{certificate.certwidth} MESI</span> con scadenza il <span className="c-value">{Format.moment(certificate.expiry, dateFormat)}</span>.</div>
            </p>
            <Row style={{textAlign: 'center'}}>
                <Col span={8}>Firma dell' atleta</Col><Col offset={8} span={8}>Il medico</Col>
            </Row>
            <Row style={{marginTop: '24px', textAlign: 'center'}}>
                <Col span={8}>_________________________________</Col><Col span={8}>Timbro della struttura</Col><Col span={8}>_________________________________</Col>
            </Row>
            <h4 style={{marginTop: '200px'}}>AVVERTENZA</h4>
            <p style={{textAlign: 'justify'}}>Contro il giudizio di <span>NON IDONEITÀ</span> è ammesso il ricorso entro 30 giorni dal ricevimento della presente comunicazione mediante inoltro con 
            raccomandata alla Commissione Regionale di Appello presso Azienda Ospedaliera Universitaria Careggi, SOD Medicina dello sport e dell'esercizio fisico (Via delle
            Oblate, 4 - Padiglione 28C - Ponte Nuovo, Careggi - 50141 Firenze)</p>
        </div>
    )
});

export const PrintCertificate = React.forwardRef(({certificate}, ref) => {//({ certificate }) {
    return (
        <div ref={ref} className="cert">
            <img src={header} alt="Intestazione Certificato Non Agonistico" />
            <h4>CERTIFICAZIONE DI IDONEITÀ ALLA PRATICA</h4>
            <h4>DI ATTIVITÀ SPORTIVA DI TIPO NON AGONISTICO</h4>
            <h4>(D.M. 24-04-2013 GU n.169 del 20-7-2013)</h4>
            <h4 style={{textAlign: 'right', marginTop: '16px'}}>Acc. ASL n° 25048 del 18/12/2022</h4>
            <p style={{textAlign: 'justify', marginTop: '24px'}}>Sig.ra / Sig <span className="c-value">{certificate.fullname.toUpperCase()}</span> nata/o a <span className="c-value">{certificate.blocality.toUpperCase()}</span> il <span className="c-value">{Format.moment(certificate.dborn, dateFormat)}</span> residente a <span className="c-value">{certificate.locality.toUpperCase()}</span> in <span className="c-value">{certificate.street.toUpperCase()}</span>. 
                Il soggetto, sulla base della visita medica da me effettuata, dei valori di pressione arteriosa rilevati, nonchè del referto del tracciato ECG eseguito in data
                <span className="c-value">{' ' + Format.moment(certificate.certdate, dateFormat)}</span>, non presenta controindicazioni in atto alla pratica di attività sportiva non agonistica.
            </p>
            <p style={{marginBottom: '24px', textAlign: 'center', marginTop: '24px'}}><b>Il presente certificato ha validità annuale dalla data del rilascio.</b></p>
            <Row style={{textAlign: 'center'}}>
                <Col span={8}>Luogo e data</Col><Col offset={8} span={8}>Il medico</Col>
            </Row>
            <Row style={{marginTop: '24px', textAlign: 'center'}}>
                <Col span={8}>Sesto Fiorentino, {' ' + Format.moment(certificate.certdate, dateFormat)} </Col><Col span={8}>Timbro della struttura</Col><Col span={8}>_________________________________</Col>
            </Row>
        </div>
    )
});