import { useVista, useModel, FormVM, Vista, ViewModel, core } from "@essenza/react";
import { Button, Steps } from "antd";
//import { BookFolder } from "../vwidget/book/BookFolder";
//import { BookSelection } from "../widget/book/BookSelection";
//import { PatientTableLite } from "../view/patient/PatientTable";
//import { FolderSelection } from "../view/folder/FolderSelection";
import { BookVisita, BookVisitaVM } from "../widget/book/BookVisita";
import { BookSelection } from "../widget/book/BookSelection";
import { FolderSelectionVM } from "../widget/folder/selection";
import { BookFolder } from "../widget/book/BookFolder";

const { Step } = Steps;

export function BookVista() {
    const vm = useVista(BookVVM);

    vm.$$init(vm => {
        vm.visit = vm.context.navdata;
    });

    console.log("BOOK-VISTA-DEBUG", vm.context.navdata);

    const steps = [
        {
            title: 'Paziente',
            content: <BookVisita visit={vm.visit} />,
        },
        {
            title: 'Visita',
            content: <BookSelection visit={vm.visit} patient={vm.patient} />,
        },
        {
            title: 'Prenota',
            content: <BookFolder visit={vm.visit} patient={vm.patient} folder={vm.folder} />,
        },
    ];

    const step = steps[vm.index];
    return (
        <Vista>
            <div className="p-2">
                <Steps responsive={false} current={vm.index} onChange={(newStep) => vm.emit("STEP", newStep)} className="hidden md:block">
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} />
                    ))}
                </Steps>
                <div className="steps-content my-2 md:my-5">{step.content}</div>
                <div className="w-full flex justify-center">
                    {vm.index !== 2 && <button className="btn-dark bg-pri" onClick={() => vm.context.navigate("agenda")}>ANULLA</button>}
                </div>
            </div>
        </Vista>
    )
}

export function BookVVM() {
    ViewModel.call(this);
    this.index = 0;

    this.use(ViewModel).as("vpatient").listen("SELECT");

    this.bind(FormVM).as("visitvm");

    this.use(FolderSelectionVM).observe("SELECT", this).last();

    this.use(BookVisitaVM).listen("ADD_PATIENT");
}

core.prototypeOf(ViewModel, BookVVM, {
    intent: {
        STEP({ data }) {
            if (this.index >= data) return;
            this.index = data;
            this.update();
        },

        async ADD_PATIENT({ data, emitter }) {
            console.log("ADD PATIENT", data);
            const validation = await this.visitvm.form.validate(true);

            if (validation.isValid) {
                this.patient = data;
                this.index = 1;
                this.update();
            }
        },

        async SELECT({ data, emitter }) {
            if (emitter === this.vpatient) {
                console.log("SELECTED", data);
                const validation = await this.visitvm.form.validate(true);

                if (validation.isValid) {
                    this.patient = data;
                    this.index = 1;
                    this.update();
                }
            }
            else {
                console.log("SELECT FOLDER in VISTA");
                this.folder = data;
                this.index = 2;
                this.update();
            }
        }
    }
})