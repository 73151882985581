
import { useVista, useModel, Vista, ViewModel, core } from "@essenza/react";
import { useMemo } from "react";
import { Button, Table } from "antd";
import { InvoiceModel } from "../data/invoice";

export function InvoicesVista() {
    const vm = useVista(InvoicesVVM);

    const [model, data] = useModel(InvoiceModel);

    vm.$$init(vm => {
        const ndata = vm.context.navdata;
        if (ndata) {
            model.of(ndata);
        }
        else {
            model.today(ndata);
        }
    });

    const col = useMemo(() => Cols(vm), [vm])

    if(!data) return <h1 className="text-center text-lg mt-3">Nessun documento da visualizzare</h1>

    return (
        <Vista>
            <Table dataSource={data} columns={col} pagination={null} />
        </Vista>
    )
}

export function InvoicesVVM(){
    ViewModel.call(this);
    //this.use(BookVisitaVM).listen("SELECT")
}

core.prototypeOf(ViewModel, InvoicesVVM, {
    intent: { 
        DETAIL({data}){
            this.context.navigate("invoice", data.id);
        }
    }
});

function Cols(vm) {
    return [
        {
            title: "Numero",
            dataIndex: "invid",
            key: "id",
            width: 70,
        },
        {
            title: "Anno",
            dataIndex: "year",
            key: "id",
            width: 70,
        },
        {
            title: "Tipo",
            render: (text, record) => record.itype === "F" ? "FATTURA" : "NOTA DI CREDITO",
            key: "fullname",
            width: 220,
        },
        {
            title: "Data",
            dataIndex: "date",
            key: "id",
            width: 'auto',
        },
        {
            title: "Nominativo",
            dataIndex: "fullname",
            key: "id",
            width: 220,
        },
        {
            title: "",
            key: "id",
            render: (text, record) => {
                return (
                    <Button onClick={() => { vm.emit("DETAIL", record); }} >VISUALIZZA</Button>
                );
            },
            width: 60,
        },
    ];
}