
import { useVista, useModel, useValue, Vista, ViewModel, core } from "@essenza/react";
import { Agenda, AgendaVM } from "../widget/agenda/agenda";
import { AgendaModel } from "../data/agendamodel";
import { useEffect } from "react";
import { DateEnum, DateInterval } from "../core/utils";
import { notification } from "antd";

export function AgendaVista() {
    useVista(AgendaVVM);
    const [agenda, data] = useModel(AgendaModel);
    const info = useValue("AGENDA_INFO", DateInterval(DateEnum.day, new Date()));

    useEffect(() => {
        agenda.eventOf(info);
    }, [agenda])

    return (
        <Vista>
            <div className="py-2" style={{ height: "100%" }}>
                {/* {data && <Agenda source={data} info={info} />} */}
                <Agenda source={data} info={info} model={agenda} />
            </div>
        </Vista>
    )
}

export function AgendaVVM() {
    ViewModel.call(this);
    this.bind(AgendaVM).as("vm").listen("*", this);
}

core.prototypeOf(ViewModel, AgendaVVM, {
    intent: { //swipe or override
        NAVIGATING: function({ data }) {
            //SET GLOBAL DATA
            this.context.setSource("AGENDA_INFO", data);
            this.$visit.eventOf(data);
        },

        BOOK: ({ context, data }) => {
            context.navigate("book", data);
        },

        TODO: ({ context, data }) => {
            context.navigate("book", data);
        },

        ACCETTAZIONE: ({ value }) => {
            notification.info({ message: "ACCETTAZIONE NON ABILITATA" });
            //c.navigate("accettazione", value);
        },

        DETAIL: ({ context, data }) => {
            context.navigate("folder", data);
        },

        CANCEL: ({ context, data }) => {
            let visit = context.newInstance('visit', { id: data});
            visit.$state = 10;
            visit.$color = "#9ca3af";
            visit.save();
        },
    }
});