import { Button, Radio } from "antd";
import { useRef, useState } from "react";
import { PrinterOutlined } from '@ant-design/icons';
import { Printer, useApp } from "@essenza/react";
import { PrintAgenda } from "../../print/PrintAgenda";

export const AgendaTool = ({ scheduler, visits, vm }) => {
    const [value, setValue] = useState(1);
    const app = useApp();

    const printer = useRef({});
    const printerf = useRef({});

    const onChange = e => {
        let v = e.target.value;
        console.log('radio checked', v, scheduler);
        setValue(v);
        if (v === 1) {
            scheduler.current.group.resources = ['branche'];
        }
        else if (v === 2) {
            scheduler.current.group.resources = ['medico'];
        }
        else {
            scheduler.current.group.resources = [];
        }
    };

    return (
        <div className="flex flex-wrap gap-2 mb-2 justify-center items-center" >
            {/* <div><b>AGENDA</b></div> */}
            <div>
                <Radio.Group onChange={onChange} value={value}>
                    <Radio value={1}>Branca</Radio>
                    <Radio value={2}>Medico</Radio>
                    <Radio value={4}>Generale</Radio>
                </Radio.Group>
            </div>
            <div className="hidden md:flex gap-2">
                <div >
                    <button className="btn-dark bg-sec" onClick={() => vm.emit("PRINT", {value: { visits: visits, type: 1 }, printer: printer, doc: PrintAgenda})}><PrinterOutlined /> AGENDA MS</button>
                </div>
                <div>
                    <button className="btn-dark bg-pri" onClick={() => vm.emit("PRINT", {value: { visits: visits, type: 2 }, printer: printerf, doc: PrintAgenda})}><PrinterOutlined /> AGENDA FISIO</button>
                </div>
            </div>

        </div>
    );
}

//app.openPopup(<Printer document={PrintAgenda} data={{ visits: visits, type: 1 }} printer={printer} />, "STAMPA AGENDA", null, { excludeCancel: true, onconfirm: () => printer.print(), confirm: "STAMPA" })
//app.openPopup(<Printer document={PrintAgenda} data={{ visits: visits, type: 2 }} printer={printerf} />, "STAMPA AGENDA", null, { excludeCancel: true, onconfirm: () => printerf.print(), confirm: "STAMPA" })