import { Button, Divider, Input, List, notification } from "antd";
import { useWidget, Widget, core, ViewModel } from "@essenza/react";

export function ExamSelection({ source, visit }) {
    const vm = useWidget(ExamSelectionVM);
    vm.visit = visit;
    console.log("SELECTION", source);
    return (
        <Widget>
            <List itemLayout="horizontal" dataSource={source}
                renderItem={item => (
                    <List.Item actions={[<Button onClick={(e) => { vm.emit("SELECTION", item); }} >Seleziona</Button>]}>
                        <div >{item.title}</div>
                    </List.Item>
                )}
            />
            <Divider />
            <div style={{ fontWeight: '600' }}>Nuovo Esame</div>
            <div className="flex gap-4">
                <div className="flex-auto"><Input onBlur={e => vm.exam().$title = e.target.value} /></div>
                <div className="flex-none"><Button onClick={(e) => { vm.emit("SELECTION", vm.exam()); }}>Inserisci</Button></div>
            </div>
        </Widget>
    )
};

export function ExamSelectionVM() {
    ViewModel.call(this);
}

core.prototypeOf(ViewModel, ExamSelectionVM, {
    exam() {
        if (!this.$exam && this.context) this.$exam = this.context.newInstance("exam", { iddoctor: this.visit.iddoctor });
        return this.$exam;
    },

    intent: {
        SELECTION({ data, token }) {
            if (!data?.title) {
                notification.warning({ message: "Inserire una descrizione per l'esame da inserire" });
                token.stopFlow = true;
            }
            else {
                this.visit.$exams.push(data);
            }
        }
    }
});

