import { Button } from "antd";

export function EventGroup({ props, model, vm, data }) {
    let start, end;
    if (data) {
        start = new Date(props.StartTime.getTime());
        const minutes = (data.EndTime.getTime() - data.StartTime.getTime()) / 60000;
        end = new Date(start.getTime())
        end.setMinutes(end.getMinutes() + minutes);
    }

    const onclick = () => {
        vm.emit("GROUP_EVENT", model.newInstance({
            start, end, id: data.id, StartTime: start, EndTime: end, Subject: "", itype: 3,
            detail: "Visita in Sospeso", branca: vm.groupid
        }));
    }

    return (
        <div className="text-center" style={{ fontSize: '18px' }}>
            <div >PRENOTA APPUNTAMENTO</div>
            <div style={{ height: '126px' }} className="mt-2">
                <b>  {model.formatInterval(start, end)}</b>
            </div>
            <Button className="mt-lg btn-pri" onClick={onclick} style={{ width: "100%" }} block >PROCEDI</Button>
            {/* <Button className="mt-lg btn-pri" onClick={() => vm.emit("GROUP_CONFIRM", model.newInstance({ start, end, id: data.id }))} style={{ width: "100%" }} block >Conferma Appuntamenti</Button> */}
        </div>)
}