import { Patient } from "../patient/form";
import { VisitList } from "../visit/list";
import { cexams } from "./certificative";
import * as yup from 'yup';

export function Cardio({ source, validate, booking}) {
    const isVisited = source.visits[0].state > 0;

    const schema = (isVisited || validate) &&
        yup.object({
            tfiscalcode: yup.string().required("Codice Fisacle richiesto.").nullable(),
            street: yup.string().required("Indirizzo è una informazione richiesta.").nullable(),
            locality: yup.object().shape({
                label: yup.string().required("Comune è una informazione richiesta.").nullable(),
            }),
            code: yup.string().required("Cap *").nullable(),
        });
        
    return (
        <>
            <Patient schema={schema} source={source.patient} />
            <VisitList booking={booking} source={source.visits} exams={cexams} />
        </>
    )
}