import { DataModel, core } from "@essenza/react";
import { DateInterval } from "../core/utils";

export function AgendaModel() {
    DataModel.call(this);
}

core.prototypeOf(DataModel, AgendaModel, {
    etype: "visit",

    eventOf: function (interval) {
        interval = interval || DateInterval();
        //this.GraphApi("[visits]", interval, "jagenda");
        return this.ExecuteQuery("jagenda", interval).then(result => {
            console.log("AGENDA API EVENTS", result);
        })
    },

    move: function(data) {
        if (data) {

            return data.save(); //In teoria dovrebbe poter salvare i cambiamenti con il nuovo FW.

            /*const node = new GraphNode("update", 0, null, null, "visit");
            const visit = { id: data.id };
            node.mutate("dstart", data.start, visit); //In questi casi posso fare utility passando string separata da virgola o arrey di fields che devono passare come mutated
            node.mutate("dend", data.end, visit); //ForceMutation
            return node.save()*/
        }
        else return Promise.reject();
    },

    formatInterval: function(start, end) {
        return end.toLocaleDateString('it-IT', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) + ' '
          + start.toLocaleTimeString("it-IT", { hour: '2-digit', minute: '2-digit' }) + '-' + end.toLocaleTimeString("it-IT", { hour: '2-digit', minute: '2-digit' });
      }
});