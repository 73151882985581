import { BaseInvoice } from "./BaseInvoice";
import {core} from "@essenza/react";

export function Invoice(){
    
    BaseInvoice.call(this, "invoice");

    //this.formatTax = (v) => this.$tax = v >=80? 2 : 0;
    this.formatVat = undefined;

    //this.items = items;
}

core.prototypeOf(BaseInvoice, Invoice,{
    formatTax: function(v){this.$tax = v >=80? 2 : 0}
});