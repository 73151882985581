import { ScheduleComponent, Day, ResourcesDirective, ResourceDirective, WorkWeek, Month, Agenda as agenda, Inject, ViewsDirective, ViewDirective, TimelineViews, DragAndDrop, Resize } from "@syncfusion/ej2-react-schedule";
import { useWidget, Widget, core, ViewModel, FormVM, Printer, $Array } from "@essenza/react";
import React, { useEffect, useRef } from "react";
import { L10n } from '@syncfusion/ej2-base';
import dayjs from 'dayjs';
import { DateEnum, DateInterval } from "../../core/utils";
import { Button, notification } from "antd";
import { EventMove } from "./move";
import { EventOption } from "./option";
import { EventInfo } from "./eventinfo";
import { AgendaTool } from "./tool";
import { EventGroup } from "./group";
import { AgendaModel } from "../../data/agendamodel";
import { FisioVM } from "../folder/fisio";

export function Agenda({ source, info, model }) {
    const vm = useWidget(AgendaVM, arguments);
    vm.info = info;
    vm.model = model;
    vm.schedule = useRef(null);
    const data = vm.temp(source);
    const onCellClick = (args) => {
        console.log("ON CELL CLICK", args);
        if (vm.schedule.current.isAdaptive) {
            vm.groupid = args.groupIndex === undefined ? 0 : (args.groupIndex + 1);
            vm.emit("BOOK", args);
            args.cancel = true;
        }
    }

    const onclick = e => {
        console.log("ON CLICK", e);
        let item = null;
        if (e.target.classList.contains("e-block-appointment")) {
            item = { id: parseInt(e.target.children[0].children[0].dataset.id) }
        }

        if (e.target.parentElement.parentElement.parentElement.classList.contains("e-block-appointment")) {
            item = { id: parseInt(e.target.parentElement.dataset.id) }
        }

        if(item){
            vm.context.openModal({
                kind: "confirm",
                content: <div>Vuoi eliminare il blocco orario?</div>,
                title: "ELIMINA BLOCCO ORARIO",
                okText: "ELIMINA",
                cancelText: "ANNULLA",
                centered: true,
                width: 450,
                onOk: () => vm.executeIntent("DELETE", item).then(r=> {
                    /*if(Array.isArray(source) && $Array.remove(source, i => i.id === item.id) > -1 ){
                        vm.invalidated = true;
                    } 
                    if(!vm.invalidated && Array.isArray(vm.tempdata) && $Array.remove(vm.tempdata, i => i.id === item.id)> -1){
                        vm.invalidated = true;
                    }
                    
                    vm.update();*/
                }),
            })
        }
    }
    useEffect(()=>{
        if(data) {vm.schedule.current.refreshEvents();}
    },[data,vm.schedule]);

    console.log("AGENDA WIDGET", data);
    //dragStart={(e) => vm.emit("DRAG_START", e)} dragStop={(e) => vm.emit("DRAG_END", e)} 
    return (
        <Widget>
            <AgendaTool visits={source} scheduler={vm.schedule} vm={vm} />
            <ScheduleComponent onClick={onclick} eventDoubleClick={e => e.cancel = true} cellDoubleClick={e => e.cancel = true} allowDragAndDrop={false} vm={vm} selectedDate={info.current} className="rounded" resizeStop={(e) => vm.emit("DRAG_END", e)} navigating={(args) => vm.emit("NAVIGATING", { args, view: info.view })} ref={vm.schedule} enableAdaptiveUI={false} eventSettings={{ dataSource: data, resourceColorField: 'color', template: vm.EventTemplate }} popupOpen={vm.popupOpen} quickInfoTemplates={{ content: (props) => <QuickInfoContent source={source} props={props} vm={vm} model={model} />, footer: (props) => <QuickInfoFooter source={source} props={props} vm={vm} model={model} /> }} group={{ byDate: true, resources: ['branche'] }} cellClick={onCellClick} width='100%' height='96%' locale="it" startHour='08:00' endHour='20:00' >
                <ResourcesDirective>
                    <ResourceDirective field='doctor' title='Medico' name='medico' allowMultiple={true} dataSource={vm.medico} textField='Title' idField='Id' > </ResourceDirective>
                    <ResourceDirective field='branca' title='Branca' name='branche' allowMultiple={true} dataSource={vm.branche} textField='Title' idField='Id' ></ResourceDirective>
                </ResourcesDirective>
                <ViewsDirective>
                    <ViewDirective option='Day' displayName="Giorno" />
                    <ViewDirective option='WorkWeek' />
                    <ViewDirective option='Month' showWeekend={false} numberOfWeeks={5} />
                    <ViewDirective option='agenda' displayName="Agenda" />
                    <ViewDirective option='TimelineDay' />
                </ViewsDirective>
                <Inject services={[Day, WorkWeek, Month, agenda, TimelineViews, DragAndDrop, Resize]} />
            </ScheduleComponent>
        </Widget>
    )
}
//<ViewDirective option='WorkWeek' dateFormat='dd-MMM-yyyy' />
function QuickInfoContent({ props, model, vm }) {
    console.log("QuikInfoContentTemplate", props);
    let content;
    if (props.elementType === "cell") {
        if (vm.moving)
            content = <EventMove props={props} model={model} vm={vm} data={vm.moving} />
        else if (vm.grouping)
            content = <EventGroup props={props} model={model} vm={vm} data={props} />
        else
            content = <EventOption props={props} model={model} vm={vm} />;
    }
    else
        content = <EventInfo props={props} model={model} vm={vm} />

    return (<div>{content}</div>);
}

function QuickInfoFooter({ props, control, model, source }) { return null }

export function AgendaVM() {
    ViewModel.call(this);
    this.schedule = null;
    this.moving = null;
    this.grouping = null;
    this.tempdata = null;

    this.use(FormVM, "todo").listen("SAVE", ({ data }) => {
        this.openPopup(false);
        data.source.detail = data.ttitle;
        data.source.itype = 1;
        data.source.Subject = " ";
        data.source.id = data.id;
        this.addTemp(data.source);
        this.update();
    });

    this.use(FormVM, "note").listen("SAVE", this);
    this.use(FormVM, "lock").listen("SAVE", this);

    //DEVO ASCOLTARE LOCK e NOTE --- SPOSTARE group (ES. branche, medico) da altra parte? #0a2f59 #d46c4e

    this.branche = [
        { Title: 'Medicina Sportiva', Id: 1, Color: '#002a3a' },
        { Title: 'Fisioterapia', Id: 2, Color: '#BF3A3A' },
    ];

    this.medico = [
        { Title: 'Dott. Fabrizio Galeotti', Id: 1, Color: '#002a3a' },
        { Title: 'Fisioterapista', Id: 2, Color: '#BF3A3A' },
    ];
}

core.prototypeOf(ViewModel, AgendaVM, {
    nodrag(args) {
        console.log("NODRAG", args);
        if (args.event.element.dataset.groupIndex !== args.event.target.dataset.groupIndex) {
            args.cancel = true;
        }
    },
    EventTemplate(props) {
        //console.log("EVENT TEMPLATE", props);
        let bgcolor;
        let color = "#FFFFFF";
        let className = "";
        if (props.color)
            bgcolor = props.color;
        else if (props.branca === 2 && (props.detail?.indexOf("VISITA MEDICA SPECIALISTICA") > -1 || props.detail?.indexOf("CONTROLLO") > -1))
            bgcolor = "#431616";
        else if (props.itype === 3) {
            bgcolor = "#E6DAC6";
            color = "#000000";
            className = "pl-2 font-medium"
        }
        else if (props.itype !== null && props.itype !== undefined) {
            bgcolor = "#fac710";
            color = "#000000";
            className = "pl-2 font-medium"
        } //Giallo: #fef445, Giallo Miro: #fac710, Celeste: #44e8fd
        else
            bgcolor = props.SecondaryColor;

        if (props.IsBlock) color = "#000000";

        return (<div data-id={props.id} className={"rounded template-wrap " + className} style={{ background: bgcolor, color: color }}>
            <div className="subject" style={{ background: props.PrimaryColor }}>
                {props.Subject + ' ' + props.StartTime.toLocaleString('it-IT', { hour: 'numeric', minute: 'numeric', }) + ' - ' + props.EndTime.toLocaleString('it-IT', { hour: 'numeric', minute: 'numeric', })}
            </div>
            <div className="time" style={{ background: props.PrimaryColor }}>
                {props.detail}
            </div>
        </div>)
    },

    popupOpen: function (evt) {
        console.log("POPUP EVENT-OPTION", evt, this);
        const vm = this.vm;
        vm.groupid = evt.data.groupIndex === undefined ? 0 : (evt.data.groupIndex + 1);

        if (evt.type === "DeleteAlert") {
            const el = evt.element.getElementsByClassName('e-quick-alertok')[0];

            if (el) {
                /*el.addEventListener("click", () => {
                    vm.emit("DELETE", evt.data)
                });*/
                el.onclick = () => {
                    vm.emit("DELETE", evt.data)
                };
            }
        }
        else if (evt.type === "Editor") {
            evt.data.__schedule = this;
            vm.moving = evt.data;
            evt.cancel = true;
            vm.emit("MOVING", evt.data);
        }
    },

    popupClose() {
        this.schedule.current.quickPopup.quickPopup.properties.close();
    },

    temp(source) {
        if (source !== this.last) this.tempdata = null;
        this.last = source;
        let data;
        if (source && Array.isArray(source))
            data = this.tempdata ? [...source, ...this.tempdata] : source;
        else
            data = this.tempdata;
            //this.invalidated = false;
        //return data ? [...data] : data;

        if(this.invalidated){
            this.invalidated = false;
            return [...data];
        }
        else
            return data;
    },

    format(data) {
        data.StartTime = data.dstart.toDate();
        data.EndTime = data.dend.toDate();
        data.branca = this.groupid;
        data.detail = data.Subject;
        data.Subject = " ";
    },

    addTemp(event) {
        if (!this.tempdata) this.tempdata = [];
        this.tempdata = [...this.tempdata, event];
    },

    intent: {
        NAVIGATING: function ({ data, token }) {
            const args = data.args;
            console.log(args);
            if (args.currentView) {
                if (args.currentView === 'Agenda')
                    data.current = DateEnum.week;
                else if (args.currentView === 'TimelineDay')
                    data.current = DateEnum.day;
                else
                    data.current = args.currentView;
            }
            token.data = DateInterval(data.current, args.currentDate);
        },

        BOOK: function ({ data, token }) {
            const item = this.model.newInstance();
            item.$dstart = dayjs(data.StartTime || data.startTime);
            item.$dend = dayjs(data.EndTime || data.endTime);
            item.$iddoctor = this.groupid;
            token.data = item;
        },

        TODO: function ({ data, token }) {
            const item = this.model.newInstance();
            item.$dstart = dayjs(data.StartTime);
            item.$dend = dayjs(data.EndTime);
            item.$itype = 1; //Activity
            token.data = item;
        },

        DELETE({ data }) {
            console.log("AGENDA DELETE", data);
            //data && data.delete();
            new AgendaModel().delete(data.id).then(()=>{
                if(Array.isArray(this.last) && $Array.remove(this.last, i => i.id === data.id) > -1 ){
                    this.invalidated = true;
                } 
                if(!this.invalidated && Array.isArray(this.tempdata) && $Array.remove(this.tempdata, i => i.id === data.id)> -1){
                    this.invalidated = true;
                }
                
                this.update();
            });
        },

        /** LOCK E NOTE SERVONO PER AGGIORNARE INFO SU AGENDA...posso anche richiamare api... */
        SAVE({ data, emitter }) {
            if (emitter.$$uid() === "note") {
                const source = this.model.source;

                for (let k = 0; k < source.length; k++) {
                    if (source[k].idpatient === data.id) {
                        source[k].extra = data.extra;
                        break;
                    }
                }
                this.update();
            }
            else if (emitter.$$uid() === "lock") {
                this.format(data);
                data.branca = data.idfolder === 123 ? 1 : 2;
                this.addTemp(data);
                this.update();
            }
            this.openPopup(false);
        },

        NOTE({ data }) {
            const source = this.model.source;

            for (let k = 0; k < source.length; k++) {
                if (source.data[k].id === data.id) {
                    source.data[k].extra = data.extra;
                    break;
                }
            }

            this.update();
        },

        MOVING({ data: info }) {
            const description = <>
                <b>{info.EndTime.toLocaleDateString('it-IT', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) + ' '}
                    {info.StartTime.toLocaleTimeString("it-IT", { hour: '2-digit', minute: '2-digit' })}-{info.EndTime.toLocaleTimeString("it-IT", { hour: '2-digit', minute: '2-digit' })}
                </b>
                <div>{info.detail}</div>
            </>

            const message = <b>SPOSTA APPUNTAMENTO - {info.Subject}</b>;

            notification.info({
                placement: 'top', message: message, description: description, key: "agenda_moving",
                duration: null, style: { width: '600px' }, onClose: () => { this.moving = null; notification.destroy("agenda_moving") }
            });
        },

        MOVE({ data }) {
            this.model.move(data).then(() => {
                this.schedule.current.quickPopup.quickPopup.properties.close();
                notification.destroy("agenda_moving");
                const visit = this.moving;
                visit.StartTime = data.source.StartTime;
                visit.EndTime = data.source.EndTime;
                this.moving = null;
                this.addTemp(visit);
                this.update();
            });
        },

        GROUPING({ data: info }) {
            this.grouping = true;
            const description = <>
                <div>Seleziona nella agenda tutte le visite da prenotare, poi procedi su CONFERMA</div>
                <Button onClick={() => this.emit("GROUP_CONFIRM")} style={{ width: "100%" }}>CONFERMA GRUPPO APPUNTAMENTI</Button>
            </>

            const message = <b>PRENOTA GRUPPO APPUNTAMENTI</b>;

            notification.info({
                placement: 'top', message: message, description: description, key: "agenda_grouping",
                duration: null, style: { width: '600px' }, onClose: () => {
                    this.grouping = null;
                    this.tempdata = null;
                    notification.destroy("agenda_grouping")
                }
            });

            this.popupClose();
        },

        GROUP_EVENT({ data }) {
            console.log("GROUP_EVENT", data);
            this.popupClose();
            this.addTemp(data);
            this.update();
        },

        GROUP_CONFIRM({ data, token }) {
            //QUI PASSO POI ALLA GESTIONE DELL' ELENCO APPUNTAMENTI COME SI FA PER UNO SOLO (BOOK)
            console.log("GROUP_CONFIRM", data);
            token.data = this.tempdata;
            this.popupClose();
            notification.destroy("agenda_grouping"); //reset grouping and tempdata too
        },

        PRINT({ data }) {
            //<Printer document={PrintAgenda} data={{ visits: visits, type: 1 }} printer={printer} />, 

            this.context.openModal({
                kind: "confirm",
                content: <div><Printer document={data.doc} data={data.value} printer={data.printer} /></div>,
                title: "STAMPA AGENDA",
                okText: "STAMPA",
                cancelText: "ANNULLA",
                centered: true,
                width: 850,
                onOk: () => data.printer.print(),
            })
        },

        /*
        MOVE: ({ model, data }) => {
            c.request(AgendaModel, m => m.move(data)).then(() => {
                model.state.moving.__schedule.current.quickPopup.quickPopup.properties.close();
                model.state.moving = null;
                notification.destroy("agenda_moving");
                const agenda = model.getGlobal("agenda");
                model.read(AgendaModel, m => m.visits(DateInterval(agenda.view, agenda.date)));
            });
        },*/

        DRAG_START: ({ data }) => {
            console.log("START DRAG", data);
            /*const state = model.getGlobal("agenda");
            if (state.view === "WorkWeek")
                args.navigation = { enable: true, timeDelay: 3000 };*/
        },

        DRAG_END({ data }) {
            console.log("DRAG STOP", data);
            //data.cancel = true;
            if (data.data) {
                const item = this.model.newInstance({ id: data.data.id });
                item.$dstart = data.data.StartTime;
                item.$dend = data.data.EndTime;
                item.save();
            }
            /*if (args.data) {
                ///ExecuteApi("update_visit", args.data, true);
                const data = args.data;
                const node = new GraphNode("update", 0, null, null, "visit");
                const visit = { id: data.id };
                node.mutate("dstart", data.StartTime, visit); //In questi casi posso fare utility passando string separata da virgola o arrey di fields che devono passare come mutated
                node.mutate("dend", data.EndTime, visit); //ForceMutation
                node.save().then(() => {
                    const agenda = model.getGlobal("agenda");
                    model.read(AgendaModel, m => m.visits(DateInterval(agenda.view, agenda.date)));
                });
            }*/
        }
    }
});

L10n.load({
    "it": {
        "schedule": {
            "day": "Giorno",
            "week": "Settimana",
            "workWeek": "Settimana",
            "month": "Mese",
            "agenda": "Agenda",
            "weekAgenda": "Agenda Settimanale",
            "workWeekAgenda": "Agenda Settimanale",
            "monthAgenda": "Agenda Mensile",
            "today": "Oggi",
            "noEvents": "Nessun Evento",
            "emptyContainer": "There are no events scheduled on this day.",
            "allDay": "All day",
            "start": "Inizio",
            "end": "Fine",
            "more": "più",
            "close": "Chiudi",
            "cancel": "Annulla",
            "noTitle": "(No Titolo)",
            "delete": "Elimina",
            "deleteEvent": "Elimina Appuntamento",
            "deleteMultipleEvent": "Delete Multiple Events",
            "selectedItems": "Items selected",
            "deleteSeries": "Delete Series",
            "edit": "Sposta Appuntamento",
            "editSeries": "Edit Series",
            "editEvent": "Visita",
            "createEvent": "Create",
            "subject": "Subject",
            "addTitle": "Add title",
            "moreDetails": "Prenota Visita",
            "save": "Salva",
            "editContent": "Do you want to edit only this event or entire series?",
            "deleteRecurrenceContent": "Do you want to delete only this event or entire series?",
            "deleteContent": "Sei sicuro di voler eliminare questo appuntamento?",
            "deleteMultipleContent": "Sei sicuro di voler eliminare l'appuntamento?",
            "newEvent": "New Event",
            "title": "Titolo",
            "location": "Luogo",
            "description": "Descrizione",
            "timezone": "Timezone",
            "startTimezone": "Start Timezone",
            "endTimezone": "End Timezone",
            "repeat": "Repeat",
            "saveButton": "Salva",
            "cancelButton": "Annulla",
            "deleteButton": "Delete",
            "recurrence": "Ricorrente",
            "wrongPattern": "The recurrence pattern is not valid.",
            "seriesChangeAlert": "The changes made to specific instances of this series will be cancelled and those events will match the series again.",
            "createError": "The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.",
            "recurrenceDateValidation": "Some months have fewer than the selected date. For these months, the occurrence will fall on the last date of the month.",
            "sameDayAlert": "Two occurrences of the same event cannot occur on the same day.",
            "editRecurrence": "Edit Recurrence",
            "repeats": "Repeats",
            "alert": "Alert",
            "startEndError": "The selected end date occurs before the start date.",
            "invalidDateError": "The entered date value is invalid.",
            "ok": "Ok",
            "occurrence": "Occurrence",
            "series": "Series",
            "previous": "Previous",
            "next": "Next",
            "timelineDay": "Orario",
            "timelineWeek": "Timeline Week",
            "timelineWorkWeek": "Timeline Work Week",
            "timelineMonth": "Timeline Month",
            "expandAllDaySection": "Expand",
            "collapseAllDaySection": "Collapse"
        },
        "recurrenceeditor": {
            "none": "None",
            "daily": "Daily",
            "weekly": "Weekly",
            "monthly": "Monthly",
            "month": "Month",
            "yearly": "Yearly",
            "never": "Never",
            "until": "Until",
            "count": "Count",
            "first": "First",
            "second": "Second",
            "third": "Third",
            "fourth": "Fourth",
            "last": "Last",
            "repeat": "Repeat",
            "repeatEvery": "Repeat Every",
            "on": "Repeat On",
            "end": "End",
            "onDay": "Day",
            "days": "Day(s)",
            "weeks": "Week(s)",
            "months": "Month(s)",
            "years": "Year(s)",
            "every": "every",
            "summaryTimes": "time(s)",
            "summaryOn": "on",
            "summaryUntil": "until",
            "summaryRepeat": "Repeats",
            "summaryDay": "day(s)",
            "summaryWeek": "week(s)",
            "summaryMonth": "month(s)",
            "summaryYear": "year(s)"
        }
    }
});