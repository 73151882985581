import { useWidget, Widget, useForm, Form, FormItem, FormVM, core } from "@essenza/react";
import { Address, CodiceFiscale, Contact } from "./person";
import dayjs from 'dayjs';
import { DatePicker, Input } from "antd";
import * as yup from 'yup';

const { TextArea } = Input;

export function Patient({ source, schema }) {
    const vm = useWidget(PatientVM);
    const form = useForm(source, vm.formatSchema(schema));

    return (
        <Widget>
            <Form form={form} layout="vertical" className="p-4 flex gap-2 flex-wrap bg-white rounded-md shadow">
                <CodiceFiscale person={source} form={form} />
                <Address person={source} form={form} ></Address>
                <Contact>
                    <FormItem name="booklet" label="Libretto">
                        <Input placeholder="Numero Libretto" style={{ width: '150px' }} />
                    </FormItem>
                    <FormItem name="dbooklet" label="Data Libretto">
                        <DatePicker placeholder="Data Libretto" format={['DD/MM/YYYY', 'DD-MM-YYYY']} />
                    </FormItem>
                    <FormItem className="grow" name="tnote" label="Note">
                        <TextArea placeholder="Note" rows={3} style={{ width: '100%' }} />
                    </FormItem>
                </Contact>
            </Form>
        </Widget>
    )
}

export function PatientVM() {
    FormVM.call(this);
}

core.prototypeOf(FormVM, PatientVM, {
    $$schema: (config) => {
        let rule = {
            tname: yup.string().required("Nome è una informazione richiesta."),
            tsurname: yup.string().required("Cognome è una informazione richiesta."),
        }

        let extra = {
            tfiscalcode: yup.string().required("Codice Fisacle richiesto.").nullable(),
            street: yup.string().required("Indirizzo è una informazione richiesta."),
            locality: yup.string().required("Informazione richiesta.").nullable(),//yup.object().shape({ label: yup.string().required("Comune è una informazione richiesta.").nullable(), })
        };

        return {
            rules: { default: yup.object(rule), complete: yup.object({...rule, ...extra})},
            formatter: {
                dborn: (v) => v ? dayjs(v) : null,
                dbooklet: (v) => v ? dayjs(new Date(v), 'DD-MM-YYYY') : null,
                //locality: (v, o) => { return { value: v, label: o['locality_label'] } },
                //blocality: (v, o) => { return { value: o['catastale'], label: o['blocality_label'] } }
            }
        }
    },
});

