import { useWidget, Widget, core, ViewModel } from "@essenza/react";
import { Cardio } from "../folder/cardio";
import { Certificative, CertificativeVM } from "../folder/certificative";
import { Fisio, FisioVM } from "../folder/fisio";

export function BookFolder({folder}) {
    const vm = useWidget(BookFolderVM);
    const t = folder.itype;
    console.log("BOOK-FOLDER", folder);
    
    let content = null;
    if (t === 0)
        content = <Certificative lite folder={folder} />;
    else if (t === 1)
        content = <Fisio lite folder={folder} />;
    else
        content = <Cardio folder={folder} />;
    
    vm.setBind(t);

    return <Widget>
        {content}
        <div className="mx-auto mt-2" style={{ width: 'fit-content' }}>
            <button className="btn-dark bg-pri" onClick={() => vm.emit("CANCEL")}>ANULLA</button>
            <button className="btn-dark bg-sec ml-2" onClick={() => vm.emitSafe("SAVE", folder, 3000)}   >CONFERMA</button>
        </div>
    </Widget>;
}

export function BookFolderVM() {
    ViewModel.call(this);
}

core.prototypeOf(ViewModel, BookFolderVM, {
    setBind(type){
        this.bind([CertificativeVM, FisioVM][type]).as("folder");
    },

    intent:{
        CANCEL(){
            this.context.navigate("agenda");
        },

        SAVE: async function({ data }) {
            console.log("FOLDER SAVE", data);
            const result = await this.folder.executeIntent("VALIDATE");
            console.log(result);
            result.isValid && data.save().then(r=>this.context.navigate("agenda"));
            /*let validation = await model.find(Patient, "patient-form").validate();
            let isValid = validation.isValid;

            if (isValid && value === 0) {
                validation = await model.find(CertificativeForm, "certificative-form").validate();
                isValid &= validation.isValid;
            }

            if (isValid) {
                data.node.save().then((r) => c.navigate("agenda"));
            }
            else {
                flow.Break();
            }*/
        },
    }
});