import { DataModel, core } from "@essenza/react";

export function InvoiceModel() {
    DataModel.call(this);
}

core.prototypeOf(DataModel, InvoiceModel, {
    etype: "invoice",
    
    detail(id) {
        return this.ExecuteQuery("invoice_detail", { id: id });
    },

    remove(id, igroup) {
        return this.ExecuteQuery("invoice_remove", { id, igroup });
    },

    of(id) {
        return this.ExecuteQuery("invoice_of", { id: id });
    },

    today() {
        return this.ExecuteQuery("invoice_today");
    },
});