import { Widget } from "@essenza/react";
import { Button, Col, Popover, Row } from "antd";
import { BsClock } from "react-icons/bs";
import { useState } from "react";
import dayjs from 'dayjs';
import { AgendaBlock } from "./AgendaBlock";
import { AgendaTodo } from "./todo";

export function EventOption({ props, model, vm }) {
    const [open, setOpen] = useState(false);
    const [todo, openTodo] = useState(false);
    const [idfolder, setFolder] = useState(123);
    console.log("EVENT-OPTION", props);
    vm.share();
    return (
        <Widget>
            <Row gutter={16}>
                <Col>
                    <BsClock style={{ fontSize: '18px' }} />
                </Col>
                <Col>
                    {props.StartTime.toLocaleTimeString("it-IT", { hour: '2-digit', minute: '2-digit' })}-{props.EndTime.toLocaleTimeString("it-IT", { hour: '2-digit', minute: '2-digit' })}
                    {'  ' + props.EndTime.toLocaleDateString('it-IT', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}
                </Col>
            </Row>
            <div className="text-base font-sans">
                <div className="mt-2">
                    <button className="btn-dark bg-red-950" onClick={() => vm.emit("BOOK", props)} style={{ width: "100%" }}>Prenota Visita</button>
                </div>
                {/* <div className="mt-2">
                <Button className="btn-pri" onClick={() => vm.emit("GROUPING", props)} style={{ width: "100%" }} block>Prenota Gruppo Visite</Button>
            </div> */}
                <div className="mt-2">
                    <Popover
                        content={<AgendaTodo es-id="todo" source={model.newInstance({
                            source: { ...props, branca: vm.groupid, Subject: "" },
                            Subject: "NUOVA ATTIVITA", dstart: dayjs(props.StartTime), dend: dayjs(props.EndTime), itype: vm.groupid
                        })} />}
                        title="NUOVA ATTIVITA"
                        trigger="click"
                        open={todo} destroyTooltipOnHide={true}
                        onOpenChange={s => openTodo(s)}
                    />
                    <button className="btn-lite bg-yellow-500" onClick={() => { vm.openPopup = openTodo; openTodo(true); console.log("EVENT-OPTION", vm.groupid); }} style={{ width: "100%" }} >Nuova Attività</button>
                </div>
                <div style={{ marginTop: '12px' }}>
                    <Popover
                        content={<AgendaBlock es-id="lock" source={model.newInstance({
                            Subject: "NON DISPONIBILE", IsBlock: true, dstart: dayjs(props.StartTime), dend: dayjs(props.EndTime), ttitle: "NON DISPONIBILE",
                            idfolder: 123, blocked: true
                        })} />}
                        title="Blocca Disponibilità Agenda"
                        trigger="click"
                        open={open} destroyTooltipOnHide={true}
                        onOpenChange={s => setOpen(s)}
                    >
                    </Popover>
                    <button className="btn-dark bg-sec w-full" onClick={() => { vm.openPopup = setOpen; setOpen(true) }} block >Blocca Orario</button>
                </div>
            </div>
        </Widget>
    )
}