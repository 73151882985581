import { Input } from "antd";
import { useWidget, Widget, widget, useForm, FormVM, Form, FormItem } from "@essenza/react";
import * as yup from 'yup';

export const AgendaNote = widget( function ({ source, rules, vm }) {
    //const vm = useWidget(FormVM); // se non metto niente, come mi ref? se ne ho più di uno? con il path =>  come si forma il path?
    rules = rules || yup.object({
        extra: yup.string().required("Informazione richiesta"),
    });
    const form = useForm(source, { rules: rules });
    return (
        <Widget>
            <Form form={form} layout='vertical' className="flex flex-col gap-2 w-80">
                <FormItem label="Nota Paziente" name="extra">
                    <Input placeholder=""></Input>
                </FormItem>
                <div className="">
                    <button className="btn-dark bg-pri w-full" onClick={() => vm.emit("SAVE")}>Conferma</button>
                </div>
            </Form>
        </Widget>
    )
}, FormVM);