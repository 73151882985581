import { Divider, message } from "antd";
import * as yup from 'yup';
import { Patient, PatientVM } from "../patient/form";
import { VisitList } from "../visit/list";
import { Certificativecard, CertificativecardVM } from "./certificativecard";
import { useWidget, Widget, core, ViewModel, Printer } from "@essenza/react";
import { PrintCertificate, PrintCompetitiveCertificate } from "../../print/PrintCertificate";
import { PatientLite } from "../patient/lite";

export const cexams = [{ title: 'ECG DINAMICO HOLTER CARDIACO' }, { title: 'ECG DINAMICO HOLTER PRESSORIO' }, { title: 'ECG A RIPOSO' },
{ title: 'ECOCOLORDOPPLER CARDIACO' },
{ title: 'RETURN TO PLAY' }, { title: 'TEST ERGOMETRICO MASSIMALE' }];

export function Certificative({ folder, state, lite }) {
    const vm = useWidget(CertificativeVM);
    if (!folder) return null; //creare componente loading o void a seconda se sta caricando o no
    vm.folder = folder;
    return (
        <Widget>
            <Divider><b>DATI PAZIENTE</b></Divider>
            {lite ? <PatientLite source={folder.patient} /> : <Patient source={folder.patient} />}
            <Divider>DATI CERTIFICATO</Divider>
            <Certificativecard schema={{ $rules: { validate: false } }} source={folder} state={state} />
            {/* Gestione complementare II Livello / Prescrizioni */}
            <VisitList source={folder.visits} exams={cexams} />
        </Widget>
    )
}

/**
 * Certificative stabilisce
 * - Cosa Validare
 * -
 */
export function CertificativeVM() {
    ViewModel.call(this);
    this.use(PatientVM).as("patient");
    this.use(CertificativecardVM).as("certificate").listen("CERTIFICATE");
}

core.prototypeOf(ViewModel, CertificativeVM, {
    intent: {
        async VALIDATE({ token }) {
            let result = await this.validateAll(this.patient.form, this.certificate.form);
            token.stopFlow = !result.isValid;
            return result;
        },

        async CERTIFICATE(token) {
            this.patient.form.rules.use("complete", true); //setRule(this.patient.$$schema({ validateAll: true }).rules);
            this.certificate.form.rules.enableOnce(true);//setRule(this.certificate.$$schema().rules);
            const response = await this.executeIntent("VALIDATE"); //.VALIDATE(token);
            if (response.isValid) {
                const folder = this.folder;

                if (!folder.icertificate || !folder.visits?.length) {
                    //Notification({message: "ATTENZIONE", description: "Tipo certificato o visita non presente"})
                    message.warning("Tipo certificato o visita non presente");
                    return;
                }

                //DO Certificate => CREATE, PRINT

                folder.$certdate = folder.certdate ? new Date(folder.certdate) : new Date(folder.visits[folder.visits.length - 1].dstart);

                if (folder.icertificate === 1) {
                    //AGONISTICO
                    if (!folder.certid) {
                        const d = new Date(folder.certdate.getTime());
                        d.setMonth(d.getMonth() + folder.certwidth);
                        d.setDate(d.getDate() - 1);
                        folder.$certex = d;
                        folder.mutation.mutated.$certid = " COALESCE((SELECT Max(COALESCE(certid,0))+1 FROM folder WHERE date_part('year',current_date)=date_part('year',certdate)),1) ";
                        folder.node.returning = "certid";
                    }
                    else if (folder.__mutation && folder.__mutation.mutated.hasOwnProperty("certwidth")) {
                        const d = new Date(folder.certdate.getTime());
                        d.setMonth(d.getMonth() + folder.certwidth);
                        d.setDate(d.getDate() - 1);
                        folder.$certex = d;
                    }
                }

                folder.save().then((v) => {
                    console.log(v);

                    if (folder.icertificate === 1 && !folder.certid) {
                        folder.certid = JSON.parse(v.data.data).certid;
                        this.certificate.form.target.setFieldValue("certid", folder.certid);
                    }

                    const patient = folder.patient;

                    const certificate = {};
                    certificate.certid = folder.certid;
                    certificate.fullname = patient.tsurname + " " + patient.tname;
                    certificate.dborn = patient.dborn;
                    certificate.blocality = patient.blocality_label;
                    certificate.locality = patient.locality_label;
                    certificate.street = patient.street;
                    certificate.tgender = patient.tgender;
                    certificate.istate = folder.istate;
                    certificate.certdate = folder.certdate;
                    certificate.sport = " ";
                    if (folder.jsport) {
                        const len = folder.jsport.length;
                        let sport;
                        for (let k = 0; k < len; k++) {
                            const i = folder.jsport[k];
                            sport = this.certificate.sports.find((sport) => sport.id === i)
                            certificate.sport += sport.title;
                            if (k < len - 1)
                                certificate.sport += ", ";
                        }
                    }
                    //certificate.sport = sports[folder.isport] || ' ';
                    certificate.certwidth = folder.certwidth || 12;
                    certificate.ndoc = folder.ndoc;
                    certificate.fromdoc = folder.fromdoc;
                    certificate.ddoc = folder.ddoc;
                    certificate.expiry = folder.certex;

                    let printdoc;

                    if (folder.icertificate === 1)
                        printdoc = PrintCompetitiveCertificate;
                    else
                        printdoc = PrintCertificate;

                    const printer = {};

                    this.context.openModal({
                        kind: "confirm",
                        content: <div><Printer document={printdoc} data={{ certificate: certificate }} printer={printer} /></div>,
                        title: "STAMPA CERTIFICATO",
                        okText: "STAMPA",
                        cancelText: "ANNULLA",
                        centered: true,
                        width: 850,
                        onOk: () => printer.print(),
                    })
                });
            }
        }
    }
});