import { useVista, useModel, Vista, ViewModel, core } from "@essenza/react";
import { PatientModel } from "../data/patientmodel";
import { Divider, Table } from "antd";
import { Patient } from "../widget/patient/form";
import { useMemo } from "react";

export function FoldersVista() {
    const vm = useVista(FoldersVVM);

    const [model, data] = useModel(PatientModel);

    vm.$$init(vm => {
        model.detail(vm.context.navdata);
    });

    const col = useMemo(() => Cols(vm), [vm])

    return (
        <Vista>
            <Patient source={data} />
            <Divider>CARTELLE CLINICHE</Divider>
            <Table dataSource={data?.folders} columns={col} pagination={null} />
        </Vista>
    )
}

export function FoldersVVM() {
    ViewModel.call(this);
    //this.use(BookVisitaVM).listen("SELECT")
}

core.prototypeOf(ViewModel, FoldersVVM, {
    intent: {
        DETAIL({ data }) {
            this.context.navigate("folder", data.id);
        }
    }
});

function Cols(vm) {
    return [
        {
            title: "Numero",
            dataIndex: "id",
            key: "id",
            width: 70,
        },
        {
            title: "Data",
            dataIndex: "idate",
            key: "id",
            width: 'auto',
        },
        {
            title: "Tipo",
            render: (text, record) => record.itype === 0 ? ("CERTIFICATO " + (record.icertificate === 2 ? "NON" : "") + "AGONISTICO") : "FISIOTERAPIA",
            key: "fullname",
            width: 220,
        },
        {
            title: "Data certificato",
            dataIndex: "certdate",
            key: "id",
            width: 'auto',
        },
        {
            title: "Scadenza",
            dataIndex: "certex",
            key: "id",
            width: 'auto',
        },
        {
            title: "",
            key: "id",
            render: (text, record) => {
                return (
                    <button className="btn-dark bg-sec w-full" onClick={() => { vm.emit("DETAIL", record); }} >VISUALIZZA</button>
                );
            },
            width: 60,
        },
    ];
}