import { DatePicker, TimePicker, Input, Button } from "antd";
import { useWidget, Widget, useForm, Form, FormItem, FormVM, widget } from "@essenza/react";
import * as yup from 'yup';
const { TextArea } = Input;

export const AgendaTodo = widget(function ({ source, vm }) {
  //const vm = useWidget(FormVM); // se non metto niente, come mi ref? se ne ho più di uno? con il path =>  come si forma il path?
  const form = useForm(source, { rules: vm.rules });
  console.log("TODO EVENT-OPTION", source );
  return (
    <Widget>
      <Form form={form} autoComplete="off" className="gap-2" >
        <div className="py-2 flex gap-2 flex-wrap">
          <FormItem className="mb-0" name="dstart" label="Data">
            <DatePicker style={{ width: '120px' }} format="DD-MM-YYYY" placeholder="Data"></DatePicker>
          </FormItem>
          <div className="flex gap-2 flex-wrap">
            <FormItem name="dstart" className="mb-0" label="Inizio">
              <TimePicker allowClear={false} style={{ width: '120px' }} minuteStep={15} format="HH:mm" placeholder="Orario Inizio"></TimePicker>
            </FormItem>
            <FormItem name="dend" className="mb-0" label="Fine">
              <TimePicker allowClear={false} style={{ width: '120px' }} minuteStep={15} format="HH:mm" placeholder="Orario Fine"></TimePicker>
            </FormItem>
          </div>
        </div>
        {/* <FormItem name="ttitle" className="mb-2">
          <Input placeholder="Titolo" />
        </FormItem> */}
        <FormItem name="ttitle" className="mb-2">
          <TextArea placeholder="Descrizione" rows={4} />
        </FormItem>
      </Form>
      <div className="flex justify-end mt-2">
        <Button  onClick={() => vm.emit("SAVE")}>Conferma</Button>
      </div>
    </Widget>
  )
}, FormVM);